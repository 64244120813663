import { useState } from "react";
import { gifter_site_url } from "../services/Services";
import { useEffect } from "react";

export default function Footer() {
  const logo = gifter_site_url + "/img/logo.png";
  const [showBanner, setShowBanner] = useState(false);

  /* Hides the Cookie banner and saves the value to localstorage */
  const handleClick = () => {
    localStorage.setItem("isCookieAccepted", "yes");
    setShowBanner(false);
  };

  /* Checks the localstorage and shows Cookie banner based on it. */
  useEffect(() => {
    let isCookieAccepted = localStorage.getItem("isCookieAccepted");
    if (isCookieAccepted === null) {
      setShowBanner(true);
    }
  }, []);

  return (
    <>
      <footer>
        <div className="container-fluid">
          <div className="container px-0 bg-footer">
            <div className="container px-0 bg-footer align-items-center py-3">
              <div className="row row-cols-12 align-items-center py-3">
                <div className="col-2">
                  <div className="row">
                    <a className="navbar-brand ms-4 ms-md-2" href="/">
                      <img
                        src={logo}
                        alt="logo"
                        className="logo-footer"
                        width="70px"
                      />
                    </a>
                  </div>
                </div>
                <div className="offset-xl-7 col-xl-2 offset-md-6 col-md-3 offset-sm-5 col-sm-5 offset-5 col-5 align-items-end">
                  <div className="row">
                    <div className="col text-end">
                      <a href="/terms" className="footer-links">
                        Terms
                      </a>
                    </div>
                    <div className="col text-end">
                      <a href="/privacy" className="footer-links">
                        Privacy&nbsp;policy
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="text-end text-body-secondary footer-rights-text">
                        © 2023, All Rights Reserved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <!-- Cookie Banner --> */}
      {showBanner && (
        <div
          id="cb-cookie-banner"
          className="alert alert-primary text-center mb-0"
          role="alert"
        >
          🍪 This website uses cookies to ensure you get the best experience on
          our website.
          <button
            type="button"
            className="btn btn-primary btn-sm ms-3"
            onClick={handleClick}
          >
            Ok
          </button>
        </div>
      )}
      {/* <!-- End of Cookie Banner --> */}
    </>
  );
}
