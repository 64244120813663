import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  uploadLogo,
  amazon_s3_logo,
  getCompanyById,
} from "../services/Services";
import Header from "../common/Header";
import Footer from "../common/Footer";

export default function UploadLogos() {
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const [message, setMessage] = useState();
  const [logo, setLogo] = useState();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const business_id = localStorage.getItem("business_id");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (business_id) {
      getCompanyById(business_id)
        .then((res) => {
          if (res.logo !== null && res.logo !== undefined && res.logo !== "")
            setLogo(amazon_s3_logo + "/" + res.logo);
        })
        .catch((err) => {
          setMessage(err.message);
          setErr(true);
        });
    }
  }, [business_id, token]);

  const onSubmit = (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const token = localStorage.getItem("token");
    if (data.file.length > 0) {
      let file_type = data.file[0].type;
      if (file_type === "image/png" || file_type === "image/jpeg") {
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("filename", data.file[0].name);
        formData.append("business_id", business_id);

        uploadLogo(token, formData, signal)
          .then((res) => {
            if (!res.data.error) {
              reset();
              setSuccess(true);
              setMessage("Logo updated successfully");
              setLogo(amazon_s3_logo + "/" + res.data.logo);
            } else if (res.data.error) {
              setMessage(res.data.error);
              setErr(true);
            }
          })
          .catch((err) => {
            setMessage(err.message);
            setErr(true);
          });
      } else {
        setError("file", {
          type: "custom",
          message: "Only the following formats are accepted: .jpeg, .jpg, .png",
        });
      }
    } else {
      setError("file", { type: "custom", message: "select file to upload" });
    }
    //cleanup
    return () => controller.abort();
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container col-xxl-4 col-lg-4 col-md-6 col-12 text-center px-5 py-5 div-bank-info">
          <div className="row align-items-center g-4 py-3">
            <div className="text-center">
              <h2 className="search-header1">Logo Upload</h2>
            </div>
            <form className="form-floating" onSubmit={handleSubmit(onSubmit)}>
              {/* logo */}
              {logo && (
                <div className="pb-3 mb-3 text-center">
                  <img
                    src={logo}
                    className="img-fluid rounded"
                    alt="logo"
                    style={{ height: "100px" }}
                  />
                </div>
              )}
              <div className={"form " + (errors.file ? "" : "mb-3")}>
                <input
                  type="file"
                  id="file"
                  placeholder="logo"
                  className={
                    "form-control " + (errors.file ? "is-invalid" : "")
                  }
                  {...register("file")}
                />
              </div>
              {errors.file && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.file.message}
                  </span>
                </div>
              )}
              {/* submit */}
              <div className="p-3">
                <button
                  type="submit"
                  className="btn btn-light btn-sm px-4 me-md-2 search-row-btn"
                >
                  Save
                </button>
              </div>
              {err && (
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              )}
              {success && (
                <div className="alert alert-success" role="alert">
                  {message}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
