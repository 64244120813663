import PayoutRow from "./PayoutRow";
export default function Payouts({ data, error, message }) {
  return (
    <div className="row align-items-center g-4 py-3">
      <div className="table-responsive mb-5">
        <table className="table text-center align-middle  table-hover mb-5 caption-top table-responsive-maxheight-400px">
          <caption>
            <b>PAY OUT TRANSACTIONS</b>
          </caption>
          <thead>
            <tr>
              <th scope="col" className="nowrap">
                Date/Time
              </th>
              <th scope="col">Transaction Amount</th>
              <th scope="col">Transaction ID</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((po) => <PayoutRow key={po.id} po={po} />)}
          </tbody>
        </table>
      </div>
      {error && (
        <div className="alert alert-danger" role="alert">
          {message}
        </div>
      )}
    </div>
  );
}
