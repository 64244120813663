import { useForm } from "react-hook-form";
import { useState } from "react";
import InputMask from "react-input-mask";
import { getGiftCardByCode } from "../services/Services";
import Header from "../common/Header";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Footer from "../common/Footer";

export default function RedeemGetCode() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const token = localStorage.getItem("token");
  const business_id = localStorage.getItem("business_id");

  const formSchema = Yup.object().shape(
    {
      code: Yup.string().required("Code is required"),
    },
    [["Code", "Code"]]
  );

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const giftcard_code = data["code"];
    getGiftCardByCode(giftcard_code, token, signal)
      .then((res) => {
        if (!res.error) {
          if (business_id === res.business_id) {
            reset();
            navigate("/giftcard-redeem/" + res.id);
          } else {
            setMessage(
              "You are trying to redeem gift card that doesn't exist in the system. Please provide valid gift card."
            );
            setError(true);
          }
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 px-4 py-3 div-login-info">
          <div className="col-12 text-start align-items-center">
            <div className="row mb-2">
              <div className="text-center">
                <h2 className="gifter-details-header1">Redeem GiftCard</h2>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="form-floating">
              {/*  code */}
              <div
                className={"row form-floating " + (errors.code ? "" : "mb-3")}
              >
                <InputMask
                  id="code"
                  mask="9999 9999 9999 9999"
                  className={
                    "form-control " + (errors.code ? "is-invalid" : "")
                  }
                  {...register("code")}
                />
                <label htmlFor="code">Gift Card Code *</label>
              </div>
              {errors.code?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.code.message}
                  </span>
                </div>
              )}

              {/* submit */}
              <div className="row text-center">
                <div className="col-12 my-3 ">
                  <button
                    type="submit"
                    className="btn btn-light btn-sm px-4 me-md-2 navbar-button"
                  >
                    Redeem
                  </button>
                </div>
              </div>
              {error && (
                <div className="row mb-2">
                  <div className="alert alert-danger text-center" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
