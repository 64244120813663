import Header from "../common/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../common/Footer";

export default function ForwardTo() {
  const navigate = useNavigate();
  const clickSignIn = () => {
    navigate("/login");
  };
  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container text-center px-5 py-5 div-login-info">
          <div className="row align-items-center g-4 py-3">
            <div className="col-12">
              <p className="hero-sub-text text-center my-5">
                Your password has been updated successfully. Please SignIn to
                your account.
              </p>
            </div>
            <div className="col-12">
              <button
                className="btn btn-light navbar-button"
                onClick={clickSignIn}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
