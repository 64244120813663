import { getFormattedDate } from "../services/Services";
export default function PayoutRow({ po }) {
  return (
    <>
      <tr key={po.id}>
        <td className="nowrap">{getFormattedDate(po.created_at)}</td>
        <td>{po.transaction_amount}</td>
        <td>{po.transaction_id}</td>
      </tr>
    </>
  );
}
