import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import {
  getGiftCard,
  giftcard_url,
  // amazon_s3_logo,
  // getBusinessById,
} from "../services/Services";
import { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";

export default function GiftCardDetails() {
  let { giftcard_id } = useParams();
  const [cardBusinessId, setCardBusinessId] = useState();
  const [giftcardCode, setGiftcardCode] = useState();
  const [originalAmt, setOriginalAmt] = useState();
  const [remainingAmt, setRemainingAmt] = useState();
  // const [logo, setLogo] = useState();

  // const image_url = process.env.REACT_APP_GIFTER_URL + "/img/card-bg.png";

  const usr_bus_id = localStorage.getItem("business_id");
  const logged_in = localStorage.getItem("logged_in");

  const [error, setError] = useState(false);
  const [message, setMessage] = useState();

  const code = giftcard_url + giftcard_id;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (giftcard_id) {
      getGiftCard(giftcard_id, signal)
        .then((res) => {
          setGiftcardCode(res.code);
          setRemainingAmt(res.remaining_amount);
          setOriginalAmt(res.original_amount);
          setCardBusinessId(res.business_id);
        })
        .catch((err) => {
          setMessage(err.message);
          setError(true);
        });
    }
    //cleanup
    return () => controller.abort();
  }, [giftcard_id]);

  const btnRedeem = () => {
    window.open(window.location.origin + "/giftcard-redeem/" + giftcard_id);
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        {!error ? (
          <div className="container col-xxl-4 col-lg-4 col-md-6 col-12 px-4 py-3 div-bank-info">
            <div className="row align-items-center g-4 py-3">
              <div className="text-center mb-3">
                <h2 className="gifter-details-header1 mb-3">Gift Card</h2>
                <div>
                  <div className="giftcard-box">
                    <div className="giftcard-amt">${originalAmt}</div>
                    <div className="d-flex justify-content-center">
                      <div style={{ width: "10rem" }}>
                        {code && (
                          <QRCode
                            size={256}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                            value={code}
                            viewBox={`0 0 256 256`}
                          />
                        )}
                      </div>
                    </div>

                    <div className="giftcard-footer">
                      <span className="giftcard-footer-start">
                        Proudly supported by
                      </span>
                      <img
                        className="giftcard-footer-logo"
                        src="https://gifuto.io/img/logo.png"
                        alt="logo"
                      />
                      <span className="giftcard-footer-end">gifuto.io</span>
                    </div>
                  </div>
                </div>
                <p className="pt-4 pb-0">
                  {" "}
                  Card Balance Amount:{" "}
                  <span className="fw-bold">$ {remainingAmt}</span>
                </p>
                <p className="p-4 fs-4">
                  Code: <span className="fw-semibold">{giftcardCode}</span>
                </p>
                {logged_in && usr_bus_id === cardBusinessId && (
                  <p>
                    <button
                      className="btn btn-light btn-sm px-4 me-md-2 navbar-button"
                      onClick={btnRedeem}
                    >
                      Redeem
                    </button>
                  </p>
                )}
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="container col-xxl-4 col-lg-4 col-md-6 col-12 px-4 py-3 div-bank-info">
            <div className="row align-items-center g-4 py-3">
              <div className="text-center mb-3">
                <h2 className="gifter-details-header1 mb-3">Gift Card</h2>
                <div className="alert alert-danger" role="alert">
                  {message === "NOT FOUND" || message === "Failed to fetch"
                    ? "You are trying to redeem gift card that doesn't exist in the system. Please contact Gifuto team for further assistance."
                    : message}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
}
