import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getAllGiftCardsByDate,
  getRemainingAndRedeemedAmount,
} from "../../services/Services";

import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Select from "react-select";
import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  format,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfDay,
  endOfDay,
} from "date-fns";
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faMoneyBill1,
} from "@fortawesome/free-regular-svg-icons";
import Giftcards from "./Giftcards";

export default function DashboardAdmin() {
  const [data, setData] = useState();
  const [remaining, setRemaining] = useState();
  const [redeemed, setRedeemed] = useState();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [duration, setDuration] = useState("1");
  const [chartData, setChartData] = useState();
  const [dateSection, setDateSection] = useState(false);
  const [sDate, setSDate] = useState();
  const [eDate, setEDate] = useState();
  const [dateError, setDateError] = useState(false);

  let { business_id } = useParams();
  const token = localStorage.getItem("token");

  const chart_options = {
    titleTextStyle: {
      fontName: "Montserrat",
      fontSize: 16,
      bold: false,
    },
    title: "Gift Card Activities",
    is3D: true,
    colors: ["#EF9C39", "#085ED5"],
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        fontName: "Montserrat",
        fontSize: 16,
        bold: false,
      },
    },
  };

  const options = [
    { value: "1", label: "Today" },
    { value: "2", label: "This Week" },
    { value: "3", label: "This Month" },
    { value: "4", label: "Custom" },
  ];

  useEffect(() => {
    var start_date, end_date;
    if (duration === "1") {
      start_date = format(startOfToday(), "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfToday(), "MM/dd/yyyy HH:mm:ss");
    } else if (duration === "2") {
      start_date = format(startOfWeek(new Date()), "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfWeek(new Date()), "MM/dd/yyyy HH:mm:ss");
    } else if (duration === "3") {
      start_date = format(startOfMonth(new Date()), "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfMonth(new Date()), "MM/dd/yyyy HH:mm:ss");
    } else if (duration === "4") {
      start_date = format(startOfDay(new Date(sDate)), "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfDay(new Date(eDate)), "MM/dd/yyyy HH:mm:ss");
    } else {
      start_date = format(startOfToday, "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfToday, "MM/dd/yyyy HH:mm:ss");
    }
    getAllGiftCardsByDate(token, business_id, start_date, end_date)
      .then((res) => {
        if (res.length > 0) setData(res);
        else setData(null);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    getRemainingAndRedeemedAmount(token, business_id, start_date, end_date)
      .then((res1) => {
        setRemaining(res1.remaining_amt);
        setRedeemed(res1.redeemed_amount);
        var tmp_chart_data = [];
        tmp_chart_data.push(["Type", "Amount"]);
        var remain_val = (res1.remaining_amt % res1.original_amt) * 100;
        var redeem_val = (res1.redeemed_amount % res1.original_amt) * 100;
        tmp_chart_data.push([
          "Remaining Amount",
          remain_val,
          // remain_val === "0" ? null : remain_val,
        ]);
        tmp_chart_data.push([
          "Redeemed Amount",
          redeem_val,
          // redeem_val === "0" ? null : redeem_val,
        ]);
        setChartData(tmp_chart_data);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
  }, [business_id, token, duration, sDate, eDate]);

  const handleCustom = () => {
    if (
      sDate === null ||
      sDate === undefined ||
      eDate === null ||
      eDate === undefined
    ) {
      setDateError(true);
    } else {
      setDateError(false);
      setDuration("4");
    }
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container text-center px-5 py-3 div-bank-info">
          <div className="row align-items-center g-4 py-3">
            <div className="col-12 col-md-5 text-center">
              <h6 className="search-header1 text-start">Merchant Dashboard</h6>
            </div>
            <div className="col-5 col-md-2 text-start">
              {dateSection && (
                <DatePicker
                  className="form-control"
                  placeholderText="Start Date"
                  selectsStart
                  selected={sDate}
                  onChange={(date) => setSDate(date)}
                  startDate={sDate}
                  showIcon
                  isClearable
                  toggleCalendarOnIconClick
                />
              )}
            </div>
            <div className="col-5 col-md-2 text-start">
              {dateSection && (
                <DatePicker
                  className="form-control"
                  placeholderText="End Date"
                  selectsEnd
                  selected={eDate}
                  onChange={(date) => setEDate(date)}
                  endDate={eDate}
                  startDate={sDate}
                  minDate={sDate}
                  showIcon
                  isClearable
                  toggleCalendarOnIconClick
                />
              )}
            </div>
            <div className="col-2 col-md-1 text-start">
              {dateSection && (
                <>
                  <button
                    className="btn btn-light btn-sm navbar-button-tiny"
                    onClick={handleCustom}
                  >
                    Go
                  </button>
                  {dateError && (
                    <div className="row align-items-start text-start mb-3">
                      <span className="span-errors" role="alert">
                        Please provide valid date range
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-12 col-md-2 text-start">
              <Select
                options={options}
                defaultValue={options[0]}
                onChange={(e) => {
                  if (e.value !== "4") {
                    setDateSection(false);
                    setSDate("");
                    setEDate("");
                    setDuration(e.value);
                  } else setDateSection(true);
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  width: 20,
                  colors: {
                    ...theme.colors,
                  },
                })}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: "rgb(204, 204, 204)",
                    backgroundColor: "white",
                  }),
                  option: (styles, { isFocused, isSelected }) => ({
                    ...styles,
                    color: "black",
                    backgroundColor: isSelected
                      ? "white"
                      : isFocused
                      ? "#FEF0FA"
                      : "white",
                    cursor: "default",
                  }),
                }}
              />
            </div>
          </div>
          <div className="row align-items-center g-4 py-3 text-center">
            <div className="row g-4">
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="card mb-4">
                    <div className="card-body">
                      <h5 className="card-title">
                        <FontAwesomeIcon
                          color="#EF9C39 !important"
                          icon={faCreditCard}
                          className="dashboard-orange"
                          style={{
                            width: "40px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                        />
                      </h5>
                      <p className="card-text dashboard-orange">
                        {Number(remaining).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                        })}
                      </p>
                      <p>Total Remaining Amount</p>
                    </div>
                  </div>
                </div>
                <div className="row g-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">
                        <FontAwesomeIcon
                          color="#EF9C39 !important"
                          icon={faMoneyBill1}
                          className="dashboard-blue"
                          style={{
                            width: "40px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                        />
                      </h5>
                      <p className="card-text dashboard-blue">
                        {Number(redeemed).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                        })}
                      </p>
                      <p>Total Redeemed Amount</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="card">
                  <div className="card-body">
                    <Chart
                      chartType="PieChart"
                      data={chartData}
                      options={chart_options}
                      width={"100%"}
                      height={"340px"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Giftcards data={data} error={error} message={message} />
        </div>
      </div>

      <Footer />
    </>
  );
}
