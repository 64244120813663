import { useEffect, useState } from "react";
import {
  // getAllGiftCardsByDate,
  // getAllPayoutsByDate,
  // getAllTransactionsByBusinessId,
  getRemainingAndRedeemedAmount,
  getDataForGraph,
} from "../../services/Services";

import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Select from "react-select";
import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  format,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfDay,
  endOfDay,
} from "date-fns";
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideNavbar from "./SideNavbar";

import {
  faFileInvoice,
  faTags,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";

export default function Dashboard() {
  const [original, setOriginal] = useState();
  const [payment, setPayment] = useState();
  const [redeemed, setRedeemed] = useState();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [duration, setDuration] = useState("1");
  const [chartData, setChartData] = useState();
  const [dateSection, setDateSection] = useState(false);
  const [sDate, setSDate] = useState();
  const [eDate, setEDate] = useState();
  const [dateError, setDateError] = useState(false);

  const business_id = localStorage.getItem("business_id");
  const token = localStorage.getItem("token");

  const chart_options = {
    titleTextStyle: {
      fontName: "Montserrat",
      fontSize: 16,
      bold: false,
    },
    title: "Gift Card Activities",
    is3D: true,
    colors: ["#EF9C39", "#085ED5"],
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        fontName: "Montserrat",
        fontSize: 16,
        bold: false,
      },
    },
    backgroundColor: "#f8f9fb",
    pointSize: 5,
    pointShape: "circle",
    vAxis: {
      format: "$#",
    },
  };

  const options = [
    { value: "1", label: "Today" },
    { value: "2", label: "This Week" },
    { value: "3", label: "This Month" },
    { value: "4", label: "Custom" },
  ];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    var start_date, end_date;
    //"MM/dd/yyyy HH:mm:ss"
    let format_date = "MM/dd/yyyy HH:mm:ss";
    if (duration === "1") {
      start_date = format(startOfToday(), format_date);
      end_date = format(endOfToday(), format_date);
    } else if (duration === "2") {
      start_date = format(startOfWeek(new Date()), format_date);
      end_date = format(endOfWeek(new Date()), format_date);
    } else if (duration === "3") {
      start_date = format(startOfMonth(new Date()), format_date);
      end_date = format(endOfMonth(new Date()), format_date);
    } else if (duration === "4") {
      start_date = format(startOfDay(new Date(sDate)), format_date);
      end_date = format(endOfDay(new Date(eDate)), format_date);
    } else {
      start_date = format(startOfToday, format_date);
      end_date = format(endOfToday, format_date);
    }

    //Payouts
    // getAllPayoutsByDate(token, business_id, start_date, end_date, signal)
    //   .then((res) => {
    //     if (res.length > 0) setPayoutData(res);
    //     else setPayoutData(null);
    //   })
    //   .catch((err) => {
    //     setMessage(err.message);
    //     setError(true);
    //   });
    //Graph
    getDataForGraph(token, business_id, start_date, end_date, signal)
      .then((res) => {
        if (res.length > 0) {
          var tmp_chart_data = [];
          tmp_chart_data.push(["Month", "Sales"]);
          res.reverse();
          res.map((i) => tmp_chart_data.push([i[0], parseFloat(i[1])]));
          setChartData(tmp_chart_data);
        }
        // else setPayoutData(null);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //Tiles
    getRemainingAndRedeemedAmount(
      token,
      business_id,
      start_date,
      end_date,
      signal
    )
      .then((res1) => {
        setOriginal(res1.original_amt);
        setPayment(res1.payment_amt);
        setRedeemed(res1.redeemed_amount);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [business_id, token, duration, sDate, eDate]);

  const handleCustom = () => {
    if (
      sDate === null ||
      sDate === undefined ||
      eDate === null ||
      eDate === undefined
    ) {
      setDateError(true);
    } else {
      setDateError(false);
      setDuration("4");
    }
  };

  // const include_transactions = "DASH";

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container text-center px-5 py-2 div-bank-info">
          <div className="row align-items-start g-4 py-3">
            <div className="col-3">
              <SideNavbar />
            </div>
            <div className="col-9">
              <div className="row align-items-center g-4 mb-3">
                <div className="col-5 offset-md-4 col-md-2 text-start">
                  {dateSection && (
                    <DatePicker
                      className="form-control"
                      placeholderText="Start Date"
                      selectsStart
                      selected={sDate}
                      onChange={(date) => setSDate(date)}
                      startDate={sDate}
                      showIcon
                      isClearable
                      toggleCalendarOnIconClick
                    />
                  )}
                </div>
                <div className="col-5 col-md-2 text-start">
                  {dateSection && (
                    <DatePicker
                      className="form-control"
                      placeholderText="End Date"
                      selectsEnd
                      selected={eDate}
                      onChange={(date) => setEDate(date)}
                      endDate={eDate}
                      startDate={sDate}
                      minDate={sDate}
                      showIcon
                      isClearable
                      toggleCalendarOnIconClick
                    />
                  )}
                </div>
                <div className="col-2 col-md-1 text-start">
                  {dateSection && (
                    <>
                      <button
                        className="btn btn-light navbar-button-tiny"
                        onClick={handleCustom}
                      >
                        Go
                      </button>
                    </>
                  )}
                </div>
                <div className="col-12 col-md-3 text-start">
                  <Select
                    options={options}
                    defaultValue={options[0]}
                    onChange={(e) => {
                      if (e.value !== "4") {
                        setDateSection(false);
                        setSDate("");
                        setEDate("");
                        setDuration(e.value);
                      } else setDateSection(true);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      width: 20,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: "#ef9c39",
                        backgroundColor: "white",
                      }),
                      option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        color: "black",
                        backgroundColor: isSelected
                          ? "white"
                          : isFocused
                          ? "#FEF0FA"
                          : "white",
                        cursor: "default",
                      }),
                    }}
                  />
                </div>
                {dateError && (
                  <div className="row align-items-start text-start mb-3">
                    <div className="col-5 offset-md-4 col-md-5 text-center">
                      <span className="span-errors" role="alert">
                        Please provide valid date range
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {/* <-- first row --> */}
              <div className="row g-4">
                <div className="col-12 col-md-4">
                  <div className="card mb-4 dashboard-bg-border">
                    <div className="card-body">
                      <h5 className="card-title">GIFT CARD SALES</h5>
                      <p className="card-text dashboard-orange">
                        <FontAwesomeIcon
                          color="#EF9C39 !important"
                          icon={faTags}
                          className="dashboard-green"
                          style={{
                            width: "40px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                        />
                        <span className="ps-3">
                          {Number(original).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="card mb-4 dashboard-bg-border">
                    <div className="card-body">
                      <h5 className="card-title">REDEEMED AMOUNT</h5>
                      <p className="card-text dashboard-orange">
                        <FontAwesomeIcon
                          color="#EF9C39 !important"
                          icon={faFileInvoice}
                          className="dashboard-green"
                          style={{
                            width: "40px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                        />
                        <span className="ps-3">
                          {Number(redeemed).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="card mb-4 dashboard-bg-border">
                    <div className="card-body">
                      <h5 className="card-title">PAYMENTS</h5>
                      <p className="card-text dashboard-orange">
                        <FontAwesomeIcon
                          color="#EF9C39 !important"
                          icon={faFileInvoiceDollar}
                          className="dashboard-green"
                          style={{
                            width: "40px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                        />
                        <span className="ps-3">
                          {Number(payment).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- second row --> */}
              <div className="row g-4">
                <div className="col-12 col-md-12">
                  <div className="card dashboard-bg-border">
                    <div className="card-body">
                      <Chart
                        chartType="LineChart"
                        data={chartData}
                        options={chart_options}
                        width={"100%"}
                        height={"340px"}
                      />
                    </div>
                  </div>
                </div>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
