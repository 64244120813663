import SubNavbar from "./SubNavbar";
import NavBar from "./NavBar";

function Header() {
  const logged_in = localStorage.getItem("logged_in");
  const user_role = localStorage.getItem("role");
  let logged_in_user;

  if (logged_in === undefined || logged_in === null) logged_in_user = false;
  else logged_in_user = true;

  return (
    <>
      {!logged_in_user ? <NavBar /> : ""}{" "}
      {logged_in_user &&
      (user_role === "1" || user_role === "2" || user_role === "3") ? (
        <SubNavbar />
      ) : (
        ""
      )}
      {logged_in_user &&
      user_role !== "1" &&
      user_role !== "2" &&
      user_role !== "3" ? (
        <NavBar />
      ) : (
        ""
      )}
    </>
  );
}
export default Header;
