import { useEffect, useState } from "react";
import { amazon_s3_logo } from "../../services/Services";

export default function SearchRow({ sr }) {
  const [logo, setLogo] = useState();
  useEffect(() => {
    if (sr.logo !== null && sr.logo !== undefined && sr.logo !== "")
      setLogo(amazon_s3_logo + "/" + sr.logo);
  }, [sr.logo]);

  return (
    <a
      href={"/dashboard-admin/" + sr.id}
      className="company-results-link mx-2"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="card company-results-card g-3" style={{ width: "18rem" }}>
        <img
          src={logo ? logo : "img/cards/gf1.png"}
          className="card-img-top rounded company-results-logo img-fluid"
          style={{ border: "none" }}
          alt="logo"
        />
        <div className="card-body">
          <p className="text-start fs-6">
            <span className="fs-5 fw-bold">
              {sr.company_name}
              <br />
            </span>
            {sr.address1}
            <br />
            {sr.address2 ? sr.address2 + "<br/>" : ""}
            {sr.city}, <br /> {sr.state} - {sr.zip}
          </p>
        </div>
      </div>
    </a>
  );
}
