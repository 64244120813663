import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { amtList } from "../services/Data";
import {
  createGifter,
  getCompanyById,
  amazon_s3_logo,
} from "../services/Services";
import InputMask from "react-input-mask";
import Header from "../common/Header";
import Footer from "../common/Footer";

export default function GifterDetails() {
  let { business_id } = useParams();
  const [business, setBusiness] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [selAmtStyle, setSelAmtStyle] = useState("");

  const [showAmt, setShowAmt] = useState(false);

  const formSchema = Yup.object().shape(
    {
      name: Yup.string().required("Your name is required"),
      phone: Yup.string().required("Your phone is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Must be a valid email"),
      recipient_name: Yup.string().required(
        "Gift recipient's name is required"
      ),
      recipient_phone: Yup.string().required(
        "Gift recipient's phone is required"
      ),
      recipient_email: Yup.string()
        .required("Gift recipient's Email is required")
        .email("Must be a valid email"),
      amount: Yup.string().required("Gift amount is required"),
      amount_txt: Yup.string()
        .ensure()
        .when("amount", {
          is: "other",
          then: Yup.string()
            .required("Please enter gift amount")
            .test(
              "chk-decimal",
              "Please provide valid amount up to $500",
              (value) =>
                (value + "").match(
                  /^(?:0\.(?:0[1-9]|[1-9][0-9]?)|[1-9](?:\.[0-9]{1,2})?|[1-9][0-9](?:\.[0-9]{1,2})?|[1-4][0-9]{2}(?:\.[0-9]{1,2})?|500(?:\.0{1,2})?)$/
                )
            )
            .typeError("Amount must be valid"),
        }),
      message: Yup.string()
        .required("Gift message is required")
        .max(256, "Message length can be up to 255 characters"),
    },
    [["amount_txt"]]
  );

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data, e) => {
    if (data["amount"] === "other") {
      data["amount"] = data["amount_txt"];
    }
    delete data["amount_txt"];
    data["business_id"] = business_id;
    const emailAddr = getValues("email");

    const controller = new AbortController();
    const signal = controller.signal;

    createGifter(data, signal)
      .then((res) => {
        if (!res.error) {
          reset();
          navigate(
            "/payment/" +
              business_id +
              "/" +
              res.gifter_id +
              "/" +
              res.giftee_id +
              "/" +
              res.giftcard_id +
              "/" +
              res.amount +
              "/" +
              emailAddr
          );
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup
    return () => controller.abort();
  };

  useEffect(() => {
    if (business_id) {
      getCompanyById(business_id)
        .then((res) => {
          setBusiness(res);
        })
        .catch((err) => {
          setMessage(err.message);
          setError(true);
        });
    }
  }, [business_id]);

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container col-xxl-6 col-lg-6 col-md-8 col-12 text-start px-5 py-5 div-bank-info">
          <div className="row row-cols-1 align-items-center g-4 py-3 text-center">
            <div className="col">
              <h2 className="gifter-details-header1">gift card purchase</h2>
            </div>
          </div>
          <div className="row row-cols-1 align-items-start g-1 py-1">
            <div className="col">
              <h4 className="gifter-details-header2">Business information</h4>
            </div>
          </div>
          {business && (
            <>
              <div className="row row-cols-12 align-items-start g-1 py-1">
                {business.logo !== null &&
                business.logo !== undefined &&
                business.logo !== "" ? (
                  <div className="col-1 align-middle text-center">
                    <img
                      src={amazon_s3_logo + "/" + business.logo}
                      className="img-fluid rounded"
                      alt="logo"
                      style={{ height: "50px" }}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="col-8 mx-3">
                  <h6>
                    {business && (
                      <p>
                        <b>{business.company_name}</b>
                        <br />
                        {business.address1} <br />
                        {business.address2 !== ""
                          ? business.address2 + "<br/>"
                          : ""}
                        {business.city}, {business.state} - {business.zip}{" "}
                      </p>
                    )}
                  </h6>
                </div>
              </div>
              <div className="row row-cols-1 align-items-start g-1 py-1">
                <div className="col-12">
                  <p className="fw-bold">
                    Please verify the business information. Once purchased eGift
                    cards cannot returned or refunded.
                  </p>
                </div>
              </div>
              <div className="row row-cols-1 align-items-start g-1 py-1">
                <div className="col">
                  <h4 className="gifter-details-header2">information</h4>
                </div>
              </div>
              <div className="row row-cols-1 align-items-center g-1 py-1">
                <div className="offset-md-0 col-10 text-start">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* gift recipient name */}
                    <div
                      className={
                        "row form-floating " +
                        (errors.recipient_name ? "" : "mb-3")
                      }
                    >
                      <input
                        type="text"
                        id="recipient_name"
                        placeholder="Recipient Name"
                        className={
                          "form-control " +
                          (errors.recipient_name ? "is-invalid" : "")
                        }
                        {...register("recipient_name")}
                      />
                      <label htmlFor="name">Recipient Name *</label>
                    </div>
                    {errors.recipient_name?.message && (
                      <div className="row align-items-start text-start mb-3">
                        <span className="span-errors" role="alert">
                          {errors.recipient_name.message}
                        </span>
                      </div>
                    )}
                    {/* gift recipient phone */}
                    <div
                      className={
                        "row form-floating " +
                        (errors.recipient_phone ? "" : "mb-3")
                      }
                    >
                      <InputMask
                        type="text"
                        id="recipient_phone"
                        mask="(999)999-9999"
                        placeholder="Recipient Phone Number"
                        className={
                          "form-control " +
                          (errors.recipient_phone ? "is-invalid" : "")
                        }
                        {...register("recipient_phone")}
                      />
                      <label htmlFor="phone">Recipient Phone Number *</label>
                    </div>
                    {errors.recipient_phone?.message && (
                      <div className="row align-items-start text-start mb-3">
                        <span className="span-errors" role="alert">
                          {errors.recipient_phone.message}
                        </span>
                      </div>
                    )}
                    {/* gift recipient email */}
                    <div
                      className={
                        "row form-floating " +
                        (errors.recipient_email ? "" : "mb-3")
                      }
                    >
                      <input
                        type="text"
                        id="recipient_email"
                        placeholder="Recipient  Email Address"
                        className={
                          "form-control " +
                          (errors.recipient_email ? "is-invalid" : "")
                        }
                        {...register("recipient_email")}
                      />
                      <label htmlFor="recipient_email">
                        Recipient Email Address *
                      </label>
                    </div>
                    {errors.recipient_email?.message && (
                      <div className="row align-items-start text-start mb-3">
                        <span className="span-errors" role="alert">
                          {errors.recipient_email.message}
                        </span>
                      </div>
                    )}
                    {/* gift amount */}
                    <div className="row gy-0">
                      <div
                        className={
                          "col-6 form-floating g-0 pe-1 " +
                          (errors.amount ? "" : "mb-1")
                        }
                      >
                        <select
                          id="amount"
                          className="form-select"
                          placeholder="Gift Amount"
                          aria-label="Amount"
                          {...register("amount", {
                            onChange: (e) => {
                              e.target.value === "other"
                                ? setShowAmt(true)
                                : setShowAmt(false);
                              setSelAmtStyle("after-select");
                            },
                          })}
                        >
                          <option value=""></option>
                          {amtList.map((x, index) => (
                            <option key={index} value={x.value}>
                              {x.name}
                            </option>
                          ))}
                        </select>
                        <label
                          htmlFor="amount"
                          className={"spl-label-select " + selAmtStyle}
                        >
                          Choose Gift Amount
                        </label>
                        {errors.amount?.message && (
                          <div className="row align-items-start text-start mb-1 ms-1 ">
                            <span className="span-errors" role="alert">
                              {errors.amount.message}
                            </span>
                          </div>
                        )}
                      </div>
                      {showAmt && (
                        <div
                          className={
                            "col-6 form-floating g-0 pe-1 " +
                            (errors.amount_txt ? "" : "mb-1")
                          }
                        >
                          <input
                            type="text"
                            id="amount_txt"
                            placeholder="Enter Amount"
                            className="form-control "
                            {...register("amount_txt")}
                          />
                          <label htmlFor="amount_txt">Enter Amount</label>
                          {errors.amount_txt?.message && (
                            <div className="row align-items-start text-start mb-1 ms-1">
                              <span className="span-errors" role="alert">
                                {errors.amount_txt.message}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="row mb-2 text-start">
                      <div
                        className="col-12 fst-italic"
                        style={{ fontSize: "0.75rem" }}
                      >
                        {business.creditcard_fee_paid_by === "merchant" ? (
                          <span>
                            {/* Note: $0.50 service fee will be added to your total
                            amount. */}
                          </span>
                        ) : (
                          <span>
                            Note: 2.9% + 50&#162; credit card fee will be added
                            to your total amount.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="py-3"></div>
                    </div>
                    {/* name */}
                    <div
                      className={
                        "row form-floating " + (errors.name ? "" : "mb-3")
                      }
                    >
                      <input
                        type="text"
                        id="name"
                        placeholder="Your Name"
                        className={
                          "form-control " + (errors.name ? "is-invalid" : "")
                        }
                        {...register("name")}
                      />
                      <label htmlFor="name">Your Name *</label>
                    </div>
                    {errors.name?.message && (
                      <div className="row align-items-start text-start mb-3">
                        <span className="span-errors" role="alert">
                          {errors.name.message}
                        </span>
                      </div>
                    )}
                    {/* phone */}
                    <div
                      className={
                        "row form-floating " + (errors.phone ? "" : "mb-3")
                      }
                    >
                      <InputMask
                        type="text"
                        id="phone"
                        mask="(999)999-9999"
                        placeholder="Your Phone Number"
                        className={
                          "form-control " + (errors.phone ? "is-invalid" : "")
                        }
                        {...register("phone")}
                      />
                      <label htmlFor="phone">Your Phone Number *</label>
                    </div>
                    {errors.phone?.message && (
                      <div className="row align-items-start text-start mb-3">
                        <span className="span-errors" role="alert">
                          {errors.phone.message}
                        </span>
                      </div>
                    )}
                    {/* email */}
                    <div
                      className={
                        "row form-floating " + (errors.email ? "" : "mb-3")
                      }
                    >
                      <input
                        type="text"
                        id="email"
                        placeholder="Your  Email Address"
                        className={
                          "form-control " + (errors.email ? "is-invalid" : "")
                        }
                        {...register("email")}
                      />
                      <label htmlFor="email">Your Email Address *</label>
                    </div>
                    {errors.email?.message && (
                      <div className="row align-items-start text-start mb-3">
                        <span className="span-errors" role="alert">
                          {errors.email.message}
                        </span>
                      </div>
                    )}
                    {/* gift message */}
                    <div
                      className={
                        "row form-floating " + (errors.message ? "" : "mb-3")
                      }
                    >
                      <textarea
                        id="message"
                        style={{ height: "100px" }}
                        placeholder="Message To Gift Recipient(Max 255 characters)"
                        className={
                          "form-control " + (errors.message ? "is-invalid" : "")
                        }
                        {...register("message")}
                      />
                      <label htmlFor="message">
                        Message To Gift Recipient(Max 255 characters) *
                      </label>
                      {errors.message?.message && (
                        <div className="row align-items-start text-start mb-3">
                          <span className="span-errors" role="alert">
                            {errors.message.message}
                          </span>
                        </div>
                      )}
                    </div>
                    {/* submit */}
                    <div className="row mb-2 pt-3 text-center">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-light navbar-button"
                        >
                          CHECKOUT
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {error && (
            <div className="row mb-2">
              <div className="alert alert-danger text-center" role="alert">
                {message}
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
