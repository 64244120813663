import GiftcardRow from "./GiftcardRow";
export default function Giftcards({
  data,
  error,
  message,
  include_transactions,
}) {
  return (
    <div className="row align-items-center g-4 py-3">
      <div className="table-responsive mb-5">
        <table className="table text-center align-middle  table-hover mb-5 caption-top table-responsive-maxheight-400px">
          <caption>
            <b>GIFT CARD SALES</b>
          </caption>
          <thead>
            <tr>
              {include_transactions === "REDEEM" && <th></th>}
              {(include_transactions === "DASH" ||
                include_transactions === "SALES") && (
                <th scope="col" className="nowrap">
                  Date/Time
                </th>
              )}
              <th scope="col">Code</th>
              <th scope="col">Giftcard Face Value($)</th>
              {include_transactions === "REDEEM" && (
                <>
                  <th scope="col">Card Balance</th>
                  <th scope="col">Merchant Value</th>
                </>
              )}
              {include_transactions === "SALES" && (
                <>
                  <th>CreditCard Processing Fee($)</th>
                  <th>CC Fee charged to</th>
                  <th>Merchant Card Value($)</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((gc) => (
                <GiftcardRow
                  key={gc.id}
                  gc={gc}
                  include_transactions={include_transactions}
                />
              ))}
          </tbody>
        </table>
      </div>
      {error && (
        <div className="alert alert-danger" role="alert">
          {message}
        </div>
      )}
    </div>
  );
}
