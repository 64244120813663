import CompanyUserRow from "./CompanyUserRow";
import { deleteUser } from "../services/Services";

export default function CompanyUsers({
  data,
  setError,
  setMessage,
  setUpdated,
  handleUpdate,
}) {
  const token = localStorage.getItem("token");

  function handleDelete(user_id) {
    const controller = new AbortController();
    const signal = controller.signal;
    deleteUser(token, user_id, signal)
      .then((res) => {
        setError(false);
        if (res.message) {
          setMessage(res.message);
          setUpdated(true);
          setTimeout(() => {
            setMessage("");
            setUpdated(false);
          }, 2000);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup
    return () => controller.abort();
  }

  return (
    <div className="row align-items-center g-1 py-3 px-3 dashboard-bg-border">
      <div className="table-responsive mb-5 px-3 py-3">
        <table className="table text-start align-middle  table-hover mb-5 caption-top">
          <caption>
            <b>USERS</b>
          </caption>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email Address</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Created on</th>
              <th scope="col">Role</th>
              <th scope="col">Active</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((user) => (
                <CompanyUserRow
                  key={user.id}
                  user={user}
                  handleUpdate={handleUpdate}
                  handleDelete={handleDelete}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
