import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { gifter_site_url } from "../services/Services";
import { useNavigate } from "react-router-dom";

export default function SubNavbar() {
  const navigate = useNavigate();
  const logo = gifter_site_url + "/img/logo.png";
  const role = localStorage.getItem("role");
  const business_name = localStorage.getItem("business_name");

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("role");
    localStorage.removeItem("business_id");
    localStorage.removeItem("business_name");
    localStorage.removeItem("account_number");
    localStorage.removeItem("logged_in");
    localStorage.removeItem("isCookieAccepted");
    // localStorage.clear()
    navigate("/");
  };

  return (
    <div className="container">
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between pb-0 pb-sm-4 navbar-div">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <a
              className="navbar-brand d-flex align-items-center ms-1 ms-md-5 link-body-emphasis text-decoration-none"
              href="/"
            >
              <img
                src={logo}
                alt="logo"
                width="70px"
                // height="34"
                className="d-inline-block align-text-top"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse my-2 my-sm-0 text-start"
              id="navbarNav"
            >
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-5">
                {role === "2" ? (
                  <>
                    <li className="nav-item nav-link px-3">
                      <a href="/dashboard" className="dropdown-item">
                        Home
                      </a>
                    </li>

                    <li className="nav-item nav-link px-3">
                      <a href="/qr-code" className="dropdown-item">
                        QR Code
                      </a>
                    </li>
                    <li className="nav-item nav-link px-3">
                      <a href="/redeem" className="dropdown-item">
                        Redeem
                      </a>
                    </li>
                  </>
                ) : (
                  ""
                )}
                {role === "3" ? (
                  <li className="nav-item nav-link px-3">
                    <a href="/redeem" className="dropdown-item">
                      Home
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li className="nav-item dropdown">
                  <a
                    href="#!"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <FontAwesomeIcon
                      icon={faUserTie}
                      className="font-awesome-icons d-none d-sm-inline"
                    />
                    <span className="d-lg-inline-flex">{business_name}</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                    <a
                      href="#!"
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </div>
                </li>
                {/* {role === "1" || role === "3" ? (
                  <li className="nav-item nav-link px-3">
                    <a
                      href="!#"
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                ) : (
                  ""
                )} */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
