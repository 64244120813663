import "./Support.css";
import { useLocation } from "react-router-dom";
import Footer from "./common/Footer";
import NavBar from "./common/NavBar";
export default function Support() {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/" ? <NavBar /> : ""}
      <div className="container text-center px-5 py-5">
        {location.pathname === "/" ? (
          <div className="row align-items-center">
            <hr className="mt-2" />
          </div>
        ) : (
          ""
        )}
        <div className="row align-items-center">
          <div className="col-12">
            <div className="mx-auto p-2 mt-4">
              <p className="fs-6 fw-bold pb-1 search-small">Support</p>
              <p className="fs-2 fw-bolder  pb-0 search-big">
                Frequently Asked Questions
              </p>
            </div>
          </div>
          <div className="row align-items-top text-start">
            <div id="accordion" className="accordion-style row row-cols-2">
              <div className="col-12 col-md-6">
                {/* Q1 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading1">
                    <h5 className="mb-0">
                      <button
                        // className="btn btn-link"
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse1"
                        // aria-expanded="true"
                        aria-expanded="false"
                        aria-controls="collapse1"
                      >
                        What is an eGift card?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse1"
                    // className="collapse show"
                    className="collapse"
                    aria-labelledby="heading1"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        An eGift card, short for "electronic gift card," is like
                        a digital version of a traditional gift card. Instead of
                        a physical piece of plastic or paper, it's a code or
                        voucher that you can send or receive through email or a
                        messaging app.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Q2 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading2">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapse2"
                      >
                        How does an eGift card work?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse2"
                    className="collapse"
                    aria-labelledby="heading2"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <ul>
                        <li>
                          You buy it: You can obtain an eGift card through the
                          GIFUTO website by either scanning the store's QR code
                          or searching for the store directly on the GIFUTO
                          website.
                        </li>
                        <li>
                          You send it: Instead of giving someone a physical
                          card, Gifuto sends the eGift card to them
                          electronically, usually via email and SMS (You will
                          also have an option to download and print the card to
                          hand deliver). You can also choose to include a
                          personalized message.
                        </li>
                        <li>
                          They use it: The recipient gets the email with the
                          eGift card code. They can use this code to make
                          purchases or redeem it at the specified store or
                          website, usually by entering the code during checkout.{" "}
                        </li>
                        <li>
                          Value: The eGift card typically has a specific
                          monetary value, and the recipient can use it to buy
                          products or services up to that value.
                        </li>
                      </ul>
                      <p>
                        In essence, it's a convenient and digital way to give
                        someone a gift, allowing them to choose what they want
                        from a particular store or service without the need for
                        a physical card.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Q3 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading3">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapse3"
                      >
                        Why Gifuto?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse3"
                    className="collapse"
                    aria-labelledby="heading3"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        GIFUTO offers a comprehensive eGift solution for small,
                        locally-owned businesses, independent consultants, and
                        freelancers, providing them with a single platform to
                        manage their electronic gift card needs.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Q4 */}
                {/* <div className="card mb-0">
                  <div className="card-header" id="heading4">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4"
                      >
                        Can eGift card delivery be scheduled?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse4"
                    className="collapse"
                    aria-labelledby="heading4"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Absolutely. You have the option to set a delivery
                        schedule. After we send the eGift card, the person who
                        purchased it (the gifter) will receive a notification.
                      </p>
                    </div>
                  </div>
                </div> */}
                {/* Q5 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading5">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="true"
                        aria-controls="collapse5"
                      >
                        Why do I need to provide eGift cards?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse5"
                    className="collapse"
                    aria-labelledby="heading5"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <ul>
                        <li>
                          Over 80% likelihood of consumer purchase Gift cards
                        </li>
                        <li>
                          Gift card sales are increasing every year and current
                          market share is approximately $204 billion.
                        </li>
                        <li>
                          75% of people who redeem gift cards end up spending
                          more than the value on their cards
                        </li>
                        <li>
                          34% of shoppers say a gift card prompts them to visit
                          a store they otherwise wouldn’t frequent (good for new
                          customer adoption).
                        </li>
                        <li>
                          Gift cards often aren’t redeemed in one trip,
                          prompting shoppers to return multiple times (good for
                          foot traffic metrics).
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Q6 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading6">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        How does the redeem process work?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse6"
                    className="collapse"
                    aria-labelledby="heading6"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        When customers wish to redeem their eGift card, they can
                        do so by presenting it on their mobile device or as a
                        printed copy at the physical store.
                      </p>
                      <ul>
                        <li>
                          Merchants have two options to facilitate the
                          redemption process: <br /> They can use the Gifuto
                          Merchant app, which is accessible on both iOS and
                          Android devices, to scan the QR code on the eGift
                          card. <br />
                          Alternatively, merchants can manually enter the
                          16-digit card number if preferred.
                        </li>
                        <li>
                          The app will promptly provide information about the
                          available balance on the eGift card.
                        </li>
                        <li>
                          The merchant then enters the specific amount the
                          customer wishes to redeem from the card.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Q7 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading7">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse7"
                      >
                        Can I prevent my employees from accessing the dashboard
                        information?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse7"
                    className="collapse"
                    aria-labelledby="heading7"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, you have the option to achieve this. By setting up
                        distinct user accounts for your employees, you can
                        restrict their access to the dashboard. Employee
                        accounts will have the sole capability to redeem gift
                        cards, while you will continue to have access to reports
                        detailing who redeemed them, the redemption time, and
                        the redeemed amount on your dashboard.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Q8 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading8">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse8"
                        aria-expanded="false"
                        aria-controls="collapse8"
                      >
                        How much will it cost me to sign up?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse8"
                    className="collapse"
                    aria-labelledby="heading8"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Signing up is completely free, and there are no
                        concealed charges. We don't levy any fees on your
                        customers when they redeem their gift cards. However,
                        when customers purchase a gift card, a credit card
                        processing fee (currently set at 2.99% + $0.30) is
                        imposed by our payment processing partner. This fee is
                        then transferred to the merchant. The merchant has the
                        choice to pass this fee along to the purchaser, but our
                        recommendation is for the merchant to absorb this cost,
                        just as they would with any other credit card
                        transaction in their store.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                {/* Q9 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading9">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse9"
                        aria-expanded="false"
                        aria-controls="collapse9"
                      >
                        How do merchants get paid?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse9"
                    className="collapse"
                    aria-labelledby="heading9"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Proceeds from eGift card sales are typically deposited
                        into the merchant’s bank account or issued as a check on
                        a weekly basis. Please be aware that ongoing disputes
                        might cause some delays in payment until they are fully
                        resolved.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Q10 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading10">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse10"
                        aria-expanded="false"
                        aria-controls="collapse10"
                      >
                        How do I promote eGift cards?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse10"
                    className="collapse"
                    aria-labelledby="heading10"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        We provide a unique QR code for your business that can
                        be easily shared via email or displayed on your website,
                        Facebook page, and other social media platforms to
                        promote your business and the eGift cards you offer.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Q11 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading11">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse11"
                        aria-expanded="false"
                        aria-controls="collapse11"
                      >
                        Does the eGift card expire?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse11"
                    className="collapse"
                    aria-labelledby="heading11"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, the card only expires when its remaining value
                        reaches $0.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Q12 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading12">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse12"
                        aria-expanded="false"
                        aria-controls="collapse12"
                      >
                        How do I keep track of my sales and redemptions?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse12"
                    className="collapse"
                    aria-labelledby="heading12"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Merchants have access to a portal where they can view
                        all sales and redemptions in real-time. Additionally, a
                        summary of all transactions is sent to you by email the
                        following morning.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Q13 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading13">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse13"
                        aria-expanded="false"
                        aria-controls="collapse13"
                      >
                        Do I need to commit to a contract?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse13"
                    className="collapse"
                    aria-labelledby="heading13"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, we don't require contracts. We firmly believe in
                        building a relationship based on trust and excellent
                        service. We believe that if we consistently provide
                        valuable service, our users will choose to stay with us
                        because they want to, not because they have to.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Q14 */}
                <div className="card mb-0">
                  <div className="card-header" id="heading14">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse14"
                        aria-expanded="false"
                        aria-controls="collapse14"
                      >
                        Have more Questions? Contact us.
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse14"
                    className="collapse"
                    aria-labelledby="heading14"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        <a href="mailto:contact@gifuto.io">Contact us</a> if you
                        have any other questions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {location.pathname !== "/" ? <Footer /> : ""}
    </>
  );
}
