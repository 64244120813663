import {
  gifter_url,
  getCompanyById,
  gifter_site_url,
} from "../services/Services";
import Header from "../common/Header";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { useEffect, useState, useRef } from "react";
import Footer from "../common/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const logo = gifter_site_url + "/img/logo.png";

export default function DisplayQRCode() {
  const token = localStorage.getItem("token");
  const business_id = localStorage.getItem("business_id");

  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [code, setCode] = useState("");
  const [business, setBusiness] = useState();
  const componentRef = useRef();

  const pageStyle = `
    @media print {
    .qrcode-div {
      width: 110mm;
      border-radius: 0px !important;
    }
    
    @page {  size: a4 landscape;
        //margin top and bottom 20mm; right and left 40mm;
        // margin: 20mm 40mm !important;
        margin: 20mm 20mm !important;
    }
    @media all {
                    .pagebreak {
                      overflow: visible; 
                    }
                }
            }
        }`;

  useEffect(() => {
    if (business_id) {
      getCompanyById(business_id)
        .then((res) => {
          const input_data = gifter_url + res.id;
          setCode(input_data);
          setBusiness(res.company_name);
        })
        .catch((err) => {
          setMessage(err.message);
          setError(true);
        });
    }
  }, [token, business_id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />

        <div
          className="container col-xxl-4 col-lg-6 col-md-8 col-12 text-center px-5 py-5 div-qrcode-info qr-code-bkground1 page"
          style={{ position: "relative" }}
        >
          <div className="row align-items-center py-2 float-end ">
            <FontAwesomeIcon
              icon={faPrint}
              className="font-awesome-icons no-print"
              style={{
                color: "#2e5169",
                cursor: "pointer",
              }}
              onClick={handlePrint}
            />
          </div>
          <div
            className="row align-items-center g-2 py-3 text-center qrcode-div"
            ref={componentRef}
          >
            <p className="qrcode-business-name pb-1">{business}</p>
            <p className="qrcode-banner1 pb-1">eGift Cards Available</p>
            <p className="qrcode-banner2 pb-0 mb-0">SCAN AND BUY</p>
            <div className="qrcode-code text-center pt-0 mt-0">
              <div className="qrcode-border text-center">
                {code && (
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    className="px-2 pt-2 pb-2"
                    value={code}
                    viewBox={`0 0 256 256`}
                  />
                )}
              </div>
              <p style={{ color: "white" }}>
                <small>or go to:</small> <br />
                <b>www.gifuto.io</b>
              </p>
            </div>
            <div className="qrcode-footer text-center pt-3 pb-0">
              <img src={logo} alt="logo" className="logo" width="70px" />
              <p className="text pt-1">
                eGift card buying experience provided by Gifuto
                <br />
                <b>www.gifuto.io</b>
              </p>
            </div>
            {error && (
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
