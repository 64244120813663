import { useNavigate } from "react-router-dom";
import { gifter_site_url } from "../services/Services";

export default function NavBar() {
  const navigate = useNavigate();
  const logo = gifter_site_url + "/img/logo.png";

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/company-user-reg");
  };

  return (
    <div className="container">
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between pb-0 pb-sm-4 navbar-div">
        <div className="container">
          <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="col-lg-2">
              <a
                className="navbar-brand d-inline-flex align-items-center mb-4w me-md-auto link-body-emphasis text-decoration-none"
                href="/"
              >
                <img
                  src={logo}
                  alt="logo"
                  width="70px"
                  // height="34"
                  className="d-inline-block align-text-top"
                />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse text-start my-2 my-sm-0"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li>
                  <a href="/" className="nav-link px-3" aria-current="page">
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="/read-more"
                    className="nav-link px-3"
                    aria-current="page"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="/how-it-works"
                    className="nav-link px-3"
                    aria-current="page"
                  >
                    How It Works
                  </a>
                </li>
                <li>
                  <a
                    href="/search"
                    className="nav-link px-3"
                    aria-current="page"
                  >
                    Buy Gift Cards
                  </a>
                </li>
                <li>
                  <a
                    href="/support"
                    className="nav-link px-3"
                    aria-current="page"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-12 col-lg-4 text-center mt-3 mt-sm-0">
              <button
                className="btn btn-light btn-sm navbar-button"
                type="submit"
                onClick={handleSignup}
              >
                SIGN UP
              </button>
              <button
                className="btn btn-light btn-sm navbar-button navbar-button-nocolor"
                type="submit"
                onClick={handleLogin}
              >
                LOGIN
              </button>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
