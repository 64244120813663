import { Outlet, Navigate } from "react-router-dom";

export default function AuthWrapper() {
  const isExpired = (token) => {
    const clearSession = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_name");
      localStorage.removeItem("role");
      localStorage.removeItem("business_id");
      localStorage.removeItem("business_name");
      localStorage.removeItem("logged_in");
      localStorage.removeItem("isCookieAccepted");
    };
    //if token is not found
    if (token === undefined || token === null) {
      return true;
    } else {
      const decode = JSON.parse(window.atob(token.split(".")[1]));
      if (decode.exp * 1000 < new Date().getTime()) {
        clearSession();
        return true;
      }
      return false;
    }
  };
  return isExpired(localStorage.getItem("token")) ? (
    <Navigate to="/login" replace />
  ) : (
    <Outlet />
  );
}
