import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./common/Footer";
import NavBar from "./common/NavBar";

export default function About() {
  const navigate = useNavigate();
  const location = useLocation();
  const onclick = () => {
    navigate("/read-more");
  };
  return (
    <>
      {location.pathname !== "/" ? <NavBar /> : ""}
      <div
        className={
          "container-fluid about-div " +
          (location.pathname !== "/" ? " pt-5 div-bank-info" : "")
        }
      >
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <img
              src="img/KnowAbout.png"
              className="d-block mx-lg-auto img-fluid about-img"
              alt="Bootstrap Themes"
              loading="lazy"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="offset-1 col-8">
                <div className="mx-auto p-2">
                  <p className="fs-6 fw-bold text-start pb-1 about-small mt-5">
                    KNOW ABOUT
                  </p>
                  <p className="fs-2 fw-bolder text-start pb-0 about-big">
                    GIFUTO
                  </p>
                  <p className="fs-6 fw-normal text-start pb-0">
                    Gifuto provides SAAS platform to sell gift cards and manage
                    redeeming process. It offers small businesses, service
                    providers and online shops to offer gift cards that can be
                    purchased online or on smartphone. It helps businesses keep
                    track of gift card purchases, redemptions, transactions,
                    balances online.
                  </p>
                  <div className="text-start mb-5">
                    <button
                      className="btn btn-light btn-sm navbar-button"
                      type="submit"
                      onClick={onclick}
                    >
                      READ MORE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {location.pathname !== "/" ? <Footer /> : ""}
    </>
  );
}
