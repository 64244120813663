import { getFormattedDate } from "../../services/Services";
export default function TransactionRow({ tra, include_transactions }) {
  return (
    <>
      {include_transactions === "REDEEM" ? (
        <>
          <tr>
            <td className="nowrap">{getFormattedDate(tra.created_at)}</td>
            <td>{tra.redeemed_amount}</td>
            <td>{tra.redeemed_by_user_name}</td>
            <td>{tra.status}</td>
            <td>{tra.transaction_number}</td>
          </tr>
        </>
      ) : (
        <tr>
          <td className="nowrap">{getFormattedDate(tra.created_at)}</td>
          <td>{tra.giftcard_code}</td>
          <td>{tra.original_amount}</td>
          <td>{tra.redeemed_amount}</td>
          <td>{tra.remaining_amount}</td>
          <td>{tra.status}</td>
        </tr>
      )}
    </>
  );
}
