import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function CompanyUserAgreement({
  handleAccept,
  show,
  handleClose,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      centered
      scrollable
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>TERMS OF SERVICE</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "50vh" }}>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">MERCHANT AGREEMENT</p>
        <p className="fs-6 fw-normal text-start pb-0">
          Welcome to GIFUTO, owned and operated by Princeton Logic LLC.
          (hereafter referred to as “Princeton Logic”, “Gifuto”, We”, “us”, or
          “our”). Please read the terms and conditions below in their entirety,
          as they constitute a legal agreement ("Agreement") between you and
          Princeton Logic LLC. To use the Service, you must accept all of the
          terms of this Agreement. If you are signing up as a representative on
          behalf of a legal entity, you represent that you have the legal
          authority to agree to these Terms on that entity’s behalf.{" "}
          <b>
            DO NOT SIGN UP IF YOU DO NOT AGREE WITH THE TERMS. IF YOU ARE UNDER
            18, PLEASE DO NOT ENTER INTO THIS AGREEMENT OR USE THIS SITE.
          </b>
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          Princeton Logic reserves the right to modify these Terms at any time
          without notice. Any change to these Terms will be effective when the
          updated Terms are posted on the Site. You can find the current version
          of the Terms at any time by going to &nbsp;
          <a href="/terms" target="_blank">
            www.gifuto.io/terms
          </a>
          . By continuing to access the Site or use the Services on or after the
          date new Terms are posted, you agree to be bound by the most recent
          version of these Terms. Princeton Logic reserves the right to modify
          the Site or terminate your access to the Site, in whole or in part, at
          any time, with or without notice.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Pricing and Payment:
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          <b>eGift Card Transactions: </b>No fees are levied for the redemption
          of eGift cards.
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          <b>Credit Card Processing Fee: </b> The Merchant acknowledges and
          agrees to cover the credit card processing fee charged by our service
          provider (Stripe) which currently stands at 2.90% plus $0.30 per
          transaction. This transaction fee will be subtracted from the
          merchant's account during the initial redemption. No fees are charged
          for businesses using our services, or for the purchase or redemption
          of eGift cards. However, we reserve the right to introduce any fees
          that we deem necessary.
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          <b>Additional Services: </b>Separate charges may be applicable for the
          use of supplementary services. Prior to utilization, you will be
          informed of these charges.
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          <b>Payment Procedures: </b> You agree to provide your business bank
          account information for transferring proceeds from eGift card sales.
          Typically, sales are deposited into the merchant’s bank account or
          issued as a check on a weekly basis. If the merchant opts for payments
          by check, a $15 processing fee per check will apply. Checks will be
          issued only in the registered business name and mailed to the address
          on file. Please note that any ongoing disputes may cause delays in
          payment until they are fully resolved.
        </p>
        {/* <p className="fs-6 fw-normal text-start pb-0">
          <b>eGift Card Handling: </b>We reserve the right to transfer eGift
          cards to another local merchant if the giftee requires. No funds,
          except the redemption amount, will be transferred to your account in
          any case. Once an e-gift card purchase is made, associated funds are
          held in a pooled custodial account at our bank until redemption by the
          recipient. You will not receive interest on these funds.
        </p> */}
        <p className="fs-6 fw-normal text-start pb-0">
          <b>Security Measures: </b> To protect against illegal or detrimental
          activity and to protect merchants and consumers, we reserve the right,
          at its sole discretion, to withhold amounts in certain circumstances
          including, but not limited to, frequent purchases for a single
          recipient using a single credit card and/or large amounts outstanding
          for a Merchant and/or whereby Merchants are not easily identifiable as
          legal entities with ongoing transparent business operations.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Our Rights:</p>
        <p className="fs-6 fw-normal text-start pb-0">
          We reserve the right to decline to provide the Service to any person
          for any reason. If information provided by you is untrue, inaccurate,
          not current, or incomplete, we reserve the right to terminate your use
          of the Service. We reserve the right to close an Account or take other
          appropriate actions if the merchant violates any of the Terms in whole
          or part or engages in any conduct, we deem inappropriate.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Privacy & Security:</p>
        <p className="fs-6 fw-normal text-start pb-0">
          Your privacy and security are paramount. Refer to our general Privacy
          Policy at www.gifuto.io/privacy, incorporated by reference in these
          Terms. You agree to abide by all terms set forth in the Privacy
          Policy, and your information will be treated in accordance with it. We
          implement measures to secure your personal information, but you
          acknowledge providing your information at your own risk, and we shall
          not be liable for unauthorized access.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Third-party Content:
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          In using our Services, you may encounter Third-Party Content. We do
          not control or assume responsibility for Third-Party Content, and you
          must evaluate and bear all risks associated with your exposure to it.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Interacting With Our Services:
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          Use of the Service requires internet access and specific software, for
          which you are responsible. If using a mobile device, you are subject
          to your agreement with your mobile device manufacturer and carrier,
          with charges from your mobile carrier being solely your
          responsibility.
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          By accessing the Service you represent and warrant to Us that: (a) you
          are at least eighteen (18) years of age; (b) you are eligible to
          register and use the Service and have the right, power, and ability to
          enter into and perform under this Agreement; (c) the company name
          registered by you when you create your Account is a legal entity (d)
          any transaction entered into by you will represent a binding
          agreement; and (e) all transactions initiated by you will comply with
          all applicable federal, state, and local laws, rules, and regulations,
          including any applicable tax laws and regulations
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Electronic Contracting:
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          Your use of the Service requires you to enter into agreements and to
          make transactions electronically. You acknowledge that your using of
          the Service constitute your agreement and intent to be bound by such
          agreements. Your agreement and intent to be bound by electronic
          submissions applies to all records relating to all transactions you
          enter on this Site, including notices of cancellation, policies,
          contracts, and applications. To access and retain your electronic
          records, you may be required to have certain hardware and software,
          which are your sole responsibility.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Interacting with Other Members:
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          You are solely responsible for your interactions with other users of
          our Service. We will not be responsible for any damage or harm
          resulting from your interactions with other users of our Service. We
          do not confirm any user's purported identity. We do not endorse any
          persons who use or register for our Services. We do not investigate
          any user's reputation, conduct, morality, criminal background, or
          verify the information that any user submits to the Site.
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          By using the Site or Services, you agree that any legal remedy or
          liability that you seek to obtain for actions or omissions of other
          users will be limited to a claim against the particular user or users
          who caused you harm. You agree not to attempt to impose liability on
          or seek any legal remedy from Princeton Logic. You release Princeton
          Logic from any and all claims, demands, damages (actual,
          consequential, nominal, punitive, or otherwise), equitable relief, and
          any other legal, equitable, and administrative remedy, of every kind
          and nature, whether known or unknown, suspected or unsuspected,
          disclosed or undisclosed, past, present, or future, arising out of or
          in any way connected with your communication or interaction with other
          users on the Site. Princeton Logic is not liable for any User Content.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Intellectual Property:
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          The Service, including graphics, user interface, audio and video
          clips, is proprietary and protected by applicable laws. You agree not
          to use such materials in any way except for Service use in compliance
          with this Agreement.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Fraud, Abuse, and Harm:
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          We may share information with law enforcement if your account is
          suspected of unauthorized, illegal, or criminal activity.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Termination:</p>
        <p className="fs-6 fw-normal text-start pb-0">
          We may, in our discretion and without liability to you, with or
          without cause, with or without prior notice and at any time: (a)
          terminate your access to our Services, (b) deactivate or delete any of
          your accounts and all related information in such accounts and (c) bar
          your access to any of such information or Services.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Legal Compliance:</p>
        <p className="fs-6 fw-normal text-start pb-0">
          You shall use our Services in a manner consistent with any and all
          applicable local, state, national and international laws and
          regulations.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Restrictions, Limitations, Reporting Requirements, and Warranties:
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          If you use our services, you do so at your sole risk. We expressly
          disclaim, and you waive, all warranties of any kind, whether express
          or implied, including, without limitation, implied warranties of
          merchantability, fitness for a particular purpose and
          non-infringement.
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          We do not warrant that (a) our services will meet your requirements;
          (b) our services will be uninterrupted, timely, secure, or error-free;
          (c) any information that you may obtain through our services will be
          accurate or reliable; (d) the quality of any products, services,
          information or other material purchased or obtained by you through our
          services will meet your expectations; or (e) any errors in any data or
          software will be corrected.
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          If you access or transmit any content through the use of our services,
          you do so at your own discretion and your sole risk. You are solely
          responsible for any loss or damage arising out of such access or
          transmission. No data, information or advice obtained by you in oral
          or written form from us or through or from our services will create
          any warranty not expressly stated in these terms.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Taxes:</p>
        <p className="fs-6 fw-normal text-start pb-0">
          Because state and local tax laws vary significantly by locality, you
          understand and agree that you are solely responsible for determining
          your own tax reporting requirements in consultation with tax advisors.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Governing Law:</p>
        <p className="fs-6 fw-normal text-start pb-0">
          These Terms shall be interpreted in accordance with the laws of the
          State of New Jersey and the United States of America, without regard
          to conflict-of-law principles.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Limitation on Liability:
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          We shall not be liable for damages of any kind (including, but not
          limited to, any direct, incidental, general, special, consequential,
          exemplary or punitive damages) even if we have been advised of the
          possibility of such damages, arising from or relating to: (a) the use
          or inability to use our services; (b) harm or damage to your property
          as a result of using our services; (c) disclosure of, unauthorized
          access to or alteration of your content; (d) any harm to you caused in
          whole or part by a third party, including but not limited to another
          user of the services; (e) statements, conduct or omissions of any user
          or other third party on our services; or (f) your or anyone else's
          conduct or acts in connection with the use of the services, including
          without limitation from interactions with other users of our services
          or persons introduced to you by our services, whether on-line or
          off-line. We shall not be liable for any failure or delay in
          performing under these terms due to causes beyond our reasonable
          control, including but not limited to acts of nature and power,
          internet, and email discontinuity. In no event will our aggregate
          liability to you or any third party in any matter arising from or
          relating to with our Services or these Terms exceed the sum of one
          hundred U.S. dollars ($100).
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Force Majeure:</p>
        <p className="fs-6 fw-normal text-start pb-0">
          We shall not be liable for any loss, expense, failure to perform, or
          delay caused by failure of communication systems, accidents, strikes,
          fire, flood, war, riot, civil strife, terrorist attack, earthquake,
          power outage, funds transfer system or government rules, acts of third
          parties, or any cause that is beyond our reasonable control.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Indemnity</p>
        <p className="fs-6 fw-normal text-start pb-0">
          You will indemnify, defend and hold us harmless (and our respective
          employees, directors, agents, affiliates and representatives) from and
          against any and all claims, costs, losses, damages, judgments, tax
          assessments, penalties, interest, and expenses (including without
          limitation reasonable attorneys' fees) arising out of any claim,
          action, audit, investigation, inquiry, or other proceeding instituted
          by a person or entity that arises out of or relates to: (a) any actual
          or alleged breach of your representations, warranties, or obligations
          set forth in this Agreement, including without limitation any
          violation of our terms and conditions; (b) your wrongful or improper
          use of the Service; (c) any transaction submitted by you through the
          Service (including without limitation the accuracy of any product
          information that you provide or any claim or dispute arising out of
          products or services offered or sold by you); (d) your violation of
          any third party right, including without limitation any right of
          privacy, publicity rights or Intellectual Property Rights; (e) your
          violation of any law, rule or regulation of the United States or any
          other country; or (f) any other party's access and/or use of the
          Service with your unique username, password or other appropriate
          security code.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Disputes</p>
        <p className="fs-6 fw-normal text-start pb-0">
          Except as expressly provided in this Agreement, these terms are a
          complete statement of the agreement between you and Princeton Logic,
          and describe the entire liability of Princeton Logic and its vendors
          and suppliers and your exclusive remedy with respect to your access
          and use of the Service. If any provision of this Agreement is invalid
          or unenforceable under applicable law, then it shall be changed and
          interpreted to accomplish the objectives of such provision to the
          greatest extent possible under applicable law, and the remaining
          provisions will continue in full force and effect.
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          Princeton Logic’s failure to enforce any right or provisions in this
          Agreement will not constitute a waiver of such or any other provision.
          We will not be responsible for failures to fulfill any obligations due
          to causes beyond its control. In the event of a conflict between this
          Agreement and any other Princeton Logic agreement or policy, this
          Agreement shall prevail on the subject matter of this Agreement.
          Headings in this Agreement are included for convenience only, and
          shall not be considered in interpreting this Agreement.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Arbitration</p>
        <p className="fs-6 fw-normal text-start pb-0">
          You agree that disputes arising under these Terms will be resolved by
          binding, individual arbitration, and by accepting these terms, you are
          waiving the right to a trial by jury or to participate in any class
          action or representative proceeding. you agree to give up your right
          to go to court to assert or defend your rights under this contract.
          Your rights will be determined by a neutral arbitrator and not a judge
          or jury.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">
          Limitation on Time to Sue
        </p>
        <p className="fs-6 fw-normal text-start pb-0">
          Unless otherwise required by law, an action or proceeding by you to
          enforce an obligation, duty or right arising under this Agreement or
          by law must commence within one year after the cause of action
          accrues.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Assignment</p>
        <p className="fs-6 fw-normal text-start pb-0">
          This Agreement, and any rights and licenses granted hereunder, may not
          be transferred or assigned by you, but may be assigned by Princeton
          Logic without restriction.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Severability</p>
        <p className="fs-6 fw-normal text-start pb-0">
          If any provision of this Agreement is held to be invalid or
          unenforceable, such determination shall not affect the validity or
          enforceability of the remaining provisions of this Agreement.
        </p>
        <p className="fs-6 fw-bold text-start pb-1 mt-1">Miscellaneous</p>
        <p className="fs-6 fw-normal text-start pb-0">
          Princeton Logic reserves the right to take steps we believe are
          reasonably necessary or appropriate to enforce or verify compliance
          with any part of this Agreement. You agree that Princeton Logic has
          the right, without liability to you, to disclose any registration data
          and other Account information to law enforcement authorities,
          government officials, or a third parties, as Princeton Logic believes
          is reasonably necessary or appropriate to enforce or verify compliance
          with any part of this Agreement. Princeton Logic is not responsible
          for any typographical errors.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="light"
          onClick={handleAccept}
          className="navbar-button"
        >
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
