import { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { getCompanyById } from "../services/Services";
import { useNavigate } from "react-router-dom";

export default function CompanyRegComplete() {
  const navigate = useNavigate();
  const [business, setBusiness] = useState();
  const [acct, setAcct] = useState();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const business_id = localStorage.getItem("business_id");
  useEffect(() => {
    if (business_id) {
      getCompanyById(business_id)
        .then((res) => {
          setBusiness(res.company_name);
          setAcct(res.account_number);
          localStorage.clear();
        })
        .catch((err) => {
          setMessage(err.message);
          setError(true);
        });
    }
  }, [business_id]);
  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container col-xxl-4 col-lg-4 col-md-6 col-12 text-center px-5 py-5 div-bank-info">
          <div className="row align-items-center g-4 py-3">
            <h2 className="gifter-details-header1">Success!!</h2>
          </div>
          <div className="row row-cols-1 align-items-center g-4 py-3">
            <div className="offset-md-0 col-12 text-center company-reg-complete">
              <p>{business} successfully registered.</p>
              <p>
                Your account number
                <br />
                {acct}
              </p>
              <p>
                <button
                  type="button"
                  className="btn btn-light navbar-button"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </p>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              )}
            </div>
            <div className="offset-md-0 col-12 text-center">
              <p>
                You will receive an email containing your business QR code.
                Please ensure to print it out and prominently display it at your
                establishment. Alternatively, you can log in to your account to
                access and print the QR code for display. If you haven't
                received the email within a few minutes, please remember to
                check your spam folder.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
