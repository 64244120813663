import AdminSearchRow from "./AdminSearchRow";
import { useState } from "react";
import { getCompanyForAdmin } from "../../services/Services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

export default function AdminSearch() {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [data, setData] = useState(null);

  let [loading, setLoading] = useState();

  const spinner = (
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  const formSchema = Yup.object().shape({
    search_text: Yup.string().required("please enter something to search.."),
  });

  const formOptions = { resolver: yupResolver(formSchema) };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    setLoading(true);
    const params = {
      search_text: data.search_text,
    };
    getCompanyForAdmin(params, signal)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
        setLoading(false);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container text-center px-5 py-3 div-bank-info">
          <div className="row align-items-center g-4 py-3">
            <div className="col-12 text-center">
              <h6 className="search-header1 text-start">Admin Search</h6>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="row g-3 form-floating"
          >
            <div className="row align-items-center">
              <div className="col-12 offset-md-2 col-md-6 px-0">
                {/* Search Text*/}
                <div className="form-floating g-0 my-3">
                  <input
                    type="text"
                    id="search_text"
                    placeholder="enter to search"
                    className={
                      "form-control " + (errors.search_text ? "is-invalid" : "")
                    }
                    {...register("search_text")}
                  />
                  <label htmlFor="search_text">
                    Search Text(Business Name, City, State) *
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-2 px-0 d-grid">
                <button
                  type="submit"
                  className="g-0 my-3 btn btn-light navbar-button"
                >
                  search now
                </button>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-12">
                {errors.search_text?.message && (
                  <div className="row align-items-center text-center my-3">
                    <span className="span-errors" role="alert">
                      * {errors.search_text?.message}
                    </span>
                  </div>
                )}
                {error && (
                  <div
                    className="alert alert-danger row align-items-center text-center my-5"
                    role="alert"
                  >
                    <p className="mb-0">{message}</p>
                  </div>
                )}
                {(data === undefined || data?.length === 0) && (
                  <div
                    className="alert alert-primary row align-items-center text-center my-5"
                    role="alert"
                  >
                    <p className="mb-0">
                      Nothing found for the given criteria.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </form>
          {loading && spinner}
          {data && (
            <div className="container text-center px-5 py-5">
              <div className="card-group g-4">
                {data &&
                  data.map((sr) => <AdminSearchRow key={sr.id} sr={sr} />)}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
