import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faDashboard,
  faLandmark,
  faUserAlt,
  faUsers,
  faGears,
  faRectangleList,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function SideNavbar() {
  const navigate = useNavigate();
  const account_number = localStorage.getItem("account_number");
  const role = localStorage.getItem("role");

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("role");
    localStorage.removeItem("business_id");
    localStorage.removeItem("business_name");
    localStorage.removeItem("account_number");
    localStorage.removeItem("logged_in");
    localStorage.removeItem("isCookieAccepted");
    // localStorage.clear()
    navigate("/");
  };

  return (
    <div className="container text-center px-5 py-3 dashboard-container">
      <div
        className="d-flex align-items-start flex-column mb-3"
        style={{ minHeight: "60vh" }}
      >
        <div className="p-2 text-center">
          <h6 className="align-content-center">Account Number</h6>
          <h6>{account_number}</h6>
        </div>
        <ul className="nav flex-column text-start">
          <li className="nav-item">
            <a className="nav-link" href="/dashboard">
              <FontAwesomeIcon
                icon={faDashboard}
                className="font-awesome-icons d-none d-sm-inline"
              />
              Dashboard
            </a>
          </li>
          <li className="nav-item">
            <a href="/company-transactions" className="nav-link">
              <FontAwesomeIcon
                icon={faRectangleList}
                className="font-awesome-icons d-none d-sm-inline"
              />
              Transactions
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/company-profile-edit">
              <FontAwesomeIcon
                icon={faUserAlt}
                className="font-awesome-icons d-none d-sm-inline"
              />
              Profile
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/upload-logo">
              <FontAwesomeIcon
                icon={faUpload}
                className="font-awesome-icons d-none d-sm-inline"
              />
              Upload Image
            </a>
          </li>
          <li className="nav-item">
            <a href="/company-bank-edit" className="nav-link">
              <FontAwesomeIcon
                icon={faLandmark}
                className="font-awesome-icons d-none d-sm-inline"
              />
              Bank Info
            </a>
          </li>
          {role === "2" && (
            <li className="nav-item">
              <a href="/company-users-edit" className="nav-link">
                <FontAwesomeIcon
                  icon={faUsers}
                  className="font-awesome-icons d-none d-sm-inline"
                />
                Manage Users
              </a>
            </li>
          )}
          <li className="nav-item">
            <a href="/company-settings-edit" className="nav-link">
              <FontAwesomeIcon
                icon={faGears}
                className="font-awesome-icons d-none d-sm-inline"
              />
              Settings
            </a>
          </li>
        </ul>
      </div>
      <div className="mt-auto p-2">
        <button
          className="align-items-end nav-1item nav-link btn"
          onClick={handleLogout}
          href="#"
        >
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="font-awesome-icons d-none d-sm-inline"
            style={{ color: "#EFA144" }}
          />
          Logout
        </button>
      </div>
    </div>
  );
}
