import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getConfirmationForGiftCard } from "../services/Services";
import Header from "../common/Header";
import Footer from "../common/Footer";

export default function CCPayemtConfirmation() {
  let { giftcard_id } = useParams();

  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [recipName, setRecipName] = useState();
  const [amount, setAmount] = useState();
  const [email, setEmail] = useState();
  const [confirmNumber, setConfirmNumber] = useState();
  const [business, setBusiness] = useState();

  useEffect(() => {
    //make database call
    const controller = new AbortController();
    const signal = controller.signal;

    getConfirmationForGiftCard(giftcard_id, signal)
      .then((res) => {
        if (!res.error) {
          setRecipName(res.recipient_name);
          setEmail(res.email);
          setAmount(res.amount);
          setConfirmNumber(res.confirmation_number);
          setBusiness(res.company_name);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [giftcard_id]);

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container col-12 px-4 py-3 div-bank-info text-start">
          <div className="row row-cols-1 align-items-center g-4 py-3 text-center">
            <div className="col">
              <h2 className="gifter-details-header1">Success!!</h2>
            </div>
          </div>
          <div className="row row-cols-1 align-items-center g-4 py-3">
            <div className="offset-md-0 col-12 text-center company-reg-complete">
              <p>
                You successfully purchased gift card ${amount} from {business}.
              </p>
              <p>Your transaction number: {confirmNumber}</p>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              )}
            </div>
            <div className="offset-md-0 col-12 text-center">
              <p>
                We will send an email to {recipName} on your behalf, providing
                all the necessary information.
              </p>
              <p>Thank you for your valuable support to your local business.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
