import TransactionRow from "./TransactionRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { getFormattedDate } from "../../services/Services";

export default function GiftcardRow({ gc, include_transactions }) {
  const [open, setOPen] = useState(false);

  const clickShowTransactions = (e) => {
    setOPen(!open);
  };
  return (
    <>
      <tr key={gc.id}>
        {include_transactions === "REDEEM" && (
          <td>
            {open ? (
              <FontAwesomeIcon
                icon={faMinus}
                className={
                  "text-primary transactions-icon " +
                  (gc.transaction.length <= 0 ? "expand-transactions-icon" : "")
                }
                onClick={clickShowTransactions}
              />
            ) : (
              <FontAwesomeIcon
                icon={faPlus}
                className={
                  "text-primary transactions-icon " +
                  (gc.transaction.length <= 0 ? "expand-transactions-icon" : "")
                }
                onClick={clickShowTransactions}
              />
            )}
          </td>
        )}
        {(include_transactions === "DASH" ||
          include_transactions === "SALES") && (
          <td className="nowrap" key={gc.created_at}>
            {getFormattedDate(gc.created_at)}
          </td>
        )}
        <td key={gc.code}>{"xxxx" + gc.code.substring(14 + 1)}</td>
        <td key={"-O-" + gc.original_amount} className="text-center">
          ${gc.original_amount}
        </td>
        {include_transactions === "REDEEM" && (
          <>
            <td key={gc.remaining_amount}>${gc.remaining_amount}</td>
            <td key={gc.merchant_card_value}>${gc.merchant_card_value}</td>
          </>
        )}
        {include_transactions === "SALES" && (
          <>
            <td key={gc.credit_card_fee}>${gc.credit_card_fee}</td>
            <td key={gc.creditcard_fee_paid_by}>{gc.creditcard_fee_paid_by}</td>
            <td key={gc.merchant_card_value}>${gc.merchant_card_value}</td>
          </>
        )}
      </tr>
      {include_transactions === "REDEEM" &&
        gc.transaction.length > 0 &&
        open && (
          <tr>
            <td colSpan={5}>
              {gc.transaction && (
                <div className="table-responsive">
                  <table className="table text-center align-middle  table-hover mb-0 caption-top">
                    <caption>List of Transactions</caption>
                    <thead>
                      <tr>
                        <th scope="col" className="nowrap">
                          Redeemed Date/Time
                        </th>
                        <th scope="col">Redeemed Amount</th>
                        <th scope="col">Redeemed By</th>
                        <th scope="col">Status</th>
                        <th scope="col">Confirmation No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gc.transaction.map((tra) => (
                        <TransactionRow
                          key={tra.id}
                          tra={tra}
                          include_transactions={include_transactions}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </td>
          </tr>
        )}
    </>
  );
}
