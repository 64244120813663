import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./common/Footer";
import NavBar from "./common/NavBar";
export default function HowItWorks() {
  const navigate = useNavigate();
  const location = useLocation();
  const onclick = () => {
    navigate("/company-user-reg");
  };
  return (
    <>
      {location.pathname !== "/" ? <NavBar /> : ""}
      <div
        className={
          "container-fluid how-to-div " +
          // (location.pathname !== "/" ? "navbar-hero-div " : "how-to-div ") +
          " my-0"
        }
      >
        <div className="container text-center px-5 py-5">
          <div className="row align-items-center pb-5">
            <div className="col-12">
              <p className="fs-6 fw-bold text-center pb-1 how-to-small">
                PROCESS
              </p>
              <p className="fs-2 fw-bolder text-center pb-0 how-to-big">
                HOW IT WORKS
              </p>
            </div>
          </div>
          {/* Step 1 */}
          <div className="row align-items-center pb-5">
            <div className="col-12 col-md-6 text-start">
              <img
                src="img/step1.png"
                className="d-block img-fluid mb-2"
                alt="Bootstrap Themes"
                loading="lazy"
              />
              <p className="fs-2 fw-bolder  pb-0 how-to-big">
                Business Sign up & Listing{" "}
              </p>
              <p className="fs-6 fw-normal text-start pb-1 pt-2">
                List your business with Gifuto platform. It will be available to
                millions of potential gift card buyers instantly.{" "}
              </p>
              <p className="fs-6 fw-normal text-start pb-5">
                It is totally free to sign up and list your business. We will
                send you signing package which will have customized QR code for
                your business
              </p>
              <img
                src="img/arrow-down.png"
                className="d-block img-fluid mb-5"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
            <div className="col-12 col-md-6 my-auto">
              <img
                src="img/BusinessSignup.jpg"
                className="d-block mx-lg-auto img-fluid how-to-img1xx"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
          </div>
          {/* Step 2 */}
          <div className="row align-items-center py-5">
            <div className="col-12 col-md-6 my-auto">
              <img
                src="img/GiftCard1-Transparent.png"
                className="d-block1 mx-lg-auto img-fluid how-to-img1xx d-none d-sm-block"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
            <div className="col-12 col-md-6 text-start">
              <img
                src="img/step2.png"
                className="d-block img-fluid mb-2"
                alt="Bootstrap Themes"
                loading="lazy"
              />
              <p className="fs-2 fw-bolder  pb-0 how-to-big">
                Sell eGift Cards
              </p>
              <p className="fs-6 fw-normal text-start pb-1 pt-2">
                Display QR code customized to your business for customers to
                see. Your customers can buy gift cards instantly using their
                phone.
              </p>
              <p className="fs-6 fw-normal text-start pb-5">
                Your business is also visible to millions of potential customers
                nationwide to search and buy gift cards.
              </p>
              <img
                src="img/arrow-down.png"
                className="d-block img-fluid"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
            <div className="col-12 col-md-6 my-auto mt-5">
              <img
                src="img/GiftCard1-Transparent.png"
                className="d-block1 mx-lg-auto img-fluid how-to-img1xx d-block d-sm-none"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
          </div>
          {/* Step 3 */}
          <div className="row align-items-center py-5">
            <div className="col-12 col-md-6 text-start">
              <img
                src="img/step3.png"
                className="d-block img-fluid mb-2"
                alt="Bootstrap Themes"
                loading="lazy"
              />
              <p className="fs-2 fw-bolder  pb-0 how-to-big">
                Redeem Gift Card and get paid
              </p>
              <p className="fs-6 fw-normal text-start pb-5">
                Gift Card receiver can redeem gift card any time at your
                business. It never expires. It is easy to redeem using mobile
                phone or website. You will have ability to view transactions and
                balance on your account on Gifuto platform.
              </p>
              <img
                src="img/arrow-down.png"
                className="d-block img-fluid"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
            <div className="col-12 col-md-6 my-auto">
              <img
                src="img/redeem_getpaid_noBKground.png"
                className="d-block mx-lg-auto img-fluid"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
          </div>
          <div className="row align-items-center py-5">
            <div className="col-12 text-center">
              <button
                className="btn btn-light btn-sm navbar-button"
                type="submit"
                onClick={onclick}
              >
                GET STARTED
              </button>
            </div>
          </div>
        </div>
      </div>
      {location.pathname !== "/" ? <Footer /> : ""}
    </>
  );
}
