import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import {
  createAdditionalUsers,
  getAllUsers,
  getUser,
  updateUser,
} from "../services/Services";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Header from "../common/Header";
import Footer from "../common/Footer";
import SideNavbar from "./dashboard/SideNavbar";
import CompanyUsers from "./CompanyUsers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

export default function CompanyUsersEdit() {
  const token = localStorage.getItem("token");
  const business_id = localStorage.getItem("business_id");
  const [userID, setUserID] = useState(null);
  const [data, setData] = useState();
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState(false);

  const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const formSchema = Yup.object().shape({
    //user
    primary_contact: Yup.string().required("User's Name is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string()
      .required("Email is required")
      .email("Must be a valid email"),
  });

  const formSchema1 = Yup.object().shape({
    //user
    primary_contact: Yup.string().required("User's Name is required"),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const formOptions1 = {
    resolver: yupResolver(formSchema1),
  };

  const {
    register,
    handleSubmit: handleAddSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const {
    register: register1,
    handleSubmit: handleUpdateSubmit,
    reset: reset1,
    setValue,
    formState: { errors: errors1 },
  } = useForm(formOptions1);

  const onEditSubmit = async (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    data["business_id"] = business_id;
    data["is_active"] = "f";
    data["user_name"] = localStorage.getItem("user_name");
    data["business_name"] = localStorage.getItem("business_name");
    createAdditionalUsers(data, signal)
      .then((res) => {
        setError(false);
        if (res.message) {
          reset();
          setMessage(res.message);
          setUpdated(true);
          setTimeout(() => {
            setMessage("");
            setUpdated(false);
          }, 2000);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getAllUsers(token, business_id, signal)
      .then((res) => {
        if (res.length > 0) setData(res);
        else setData(null);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [business_id, token, updated]);

  const handleEdit = () => {
    setUpdate(false);
    setAdd(true);
  };

  const handleCancel = () => {
    reset();
    reset1();
    setAdd(false);
    setUpdate(false);
    setError(false);
    setUpdated(false);
    setMessage(null);
  };

  const handleUpdate = (user_id) => {
    setAdd(false);
    setUpdate(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setUserID(user_id);
    getUser(token, user_id, signal)
      .then((res) => {
        if (JSON.stringify(res) !== "{}") {
          setValue("primary_contact", res.primary_contact);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };

  const onUpdateSubmit = (data) => {
    const controller = new AbortController();
    const signal = controller.signal;

    updateUser(token, userID, data, signal)
      .then((res) => {
        setError(false);
        if (res.message) {
          reset1();
          setMessage(res.message);
          setUpdated(true);
          setTimeout(() => {
            setMessage("");
            setUpdated(false);
          }, 2000);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };

  return (
    <div className="container-fluid navbar-hero-div">
      <Header />
      <div className="container text-center px-5 py-2 div-bank-info">
        <div className="row row-cols-2 align-items-start g-4 py-3">
          <div className="col-3">
            <SideNavbar />
          </div>
          <div className="col-9 text-start py-0 px-3">
            <div className="row mb-1 text-start">
              <CompanyUsers
                data={data}
                setError={setError}
                setMessage={setMessage}
                setUpdated={setUpdated}
                handleUpdate={handleUpdate}
              />
            </div>
            <div className="row mb-5 text-start">
              <div
                className="col-8 fst-italic mt-2"
                style={{ fontSize: "0.75rem" }}
              >
                {/* *First user is free. Additional users will be charged $1.99 per
                month per user. */}
              </div>
              <div className="col-4 text-end">
                <button
                  className="btn btn-light navbar-button"
                  type="button"
                  onClick={handleEdit}
                >
                  Add Users
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      color: "white !important",
                    }}
                  />
                </button>
              </div>
            </div>
            {add && (
              <div className="row dashboard-bg-border">
                <form
                  onSubmit={handleAddSubmit(onEditSubmit)}
                  className="form-floating px-5 py-5"
                >
                  <div className="row mb-2">
                    <div className="col-12 float-start">
                      {/* User information */}
                      <h4 className="gifter-details-header2">ADD USER</h4>
                    </div>
                  </div>
                  {/* Business contact */}
                  <div
                    className={
                      "row form-floating " +
                      (errors.primary_contact ? "" : "mb-3")
                    }
                  >
                    <input
                      type="text"
                      id="primary_contact"
                      placeholder="User's Name"
                      className={
                        "form-control " +
                        (errors.primary_contact ? "is-invalid" : "")
                      }
                      {...register("primary_contact")}
                    />
                    <label htmlFor="primary_contact">User's Name *</label>
                  </div>
                  {errors.primary_contact?.message && (
                    <div className="row align-items-start text-start mb-3">
                      <span className="span-errors" role="alert">
                        {errors.primary_contact.message}
                      </span>
                    </div>
                  )}
                  {/* contact phone*/}
                  <div
                    className={
                      "row form-floating " + (errors.phone ? "" : "mb-3")
                    }
                  >
                    <input
                      type="text"
                      id="phone"
                      placeholder="Phone Number(xxx-xxx-xxxx)"
                      className={
                        "form-control " + (errors.phone ? "is-invalid" : "")
                      }
                      {...register("phone")}
                    />
                    <label htmlFor="phone">Phone Number *</label>
                  </div>
                  {errors.phone?.message && (
                    <div className="row align-items-start text-start mb-3">
                      <span className="span-errors" role="alert">
                        {errors.phone.message}
                      </span>
                    </div>
                  )}
                  {/*  email address */}
                  <div
                    className={
                      "row form-floating " + (errors.email ? "" : "mb-3")
                    }
                  >
                    <input
                      type="text"
                      id="email"
                      placeholder="Business Name"
                      className={
                        "form-control " + (errors.email ? "is-invalid" : "")
                      }
                      {...register("email")}
                    />
                    <label htmlFor="email">Email Address *</label>
                  </div>
                  {errors.email?.message && (
                    <div className="row align-items-start text-start mb-3">
                      <span className="span-errors" role="alert">
                        {errors.email.message}
                      </span>
                    </div>
                  )}
                  <div className="row mb-2 text-start">
                    <div
                      className="col-12 fst-italic"
                      style={{ fontSize: "0.75rem" }}
                    >
                      User will receive seperate email to setup their password.
                    </div>
                  </div>
                  {/* submit */}
                  <div className="row mb-2 pt-3 text-center">
                    <div className="col-12 my-3">
                      <button
                        type="submit"
                        className="btn btn-light navbar-button"
                      >
                        SAVE
                      </button>
                      <button
                        type="button"
                        className="btn btn-light navbar-button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {update && (
              <div className="row dashboard-bg-border">
                <form
                  onSubmit={handleUpdateSubmit(onUpdateSubmit)}
                  className="form-floating px-5 py-5"
                >
                  <div className="row mb-2">
                    <div className="col-12 float-start">
                      {/* User information */}
                      <h4 className="gifter-details-header2">EDIT USER</h4>
                    </div>
                  </div>

                  {/* Business contact */}
                  <div
                    className={
                      "row form-floating " +
                      (errors1.primary_contact ? "" : "mb-3")
                    }
                  >
                    <input
                      type="text"
                      id="primary_contact"
                      placeholder="User's Name"
                      className={
                        "form-control " +
                        (errors1.primary_contact ? "is-invalid" : "")
                      }
                      {...register1("primary_contact")}
                    />
                    <label htmlFor="primary_contact">User's Name *</label>
                  </div>
                  {errors1.primary_contact?.message && (
                    <div className="row align-items-start text-start mb-3">
                      <span className="span-errors" role="alert">
                        {errors1.primary_contact.message}
                      </span>
                    </div>
                  )}

                  {/* submit */}
                  <div className="row mb-2 pt-3 text-center">
                    <div className="col-12 my-3">
                      <button
                        type="submit"
                        className="btn btn-light navbar-button"
                      >
                        SAVE
                      </button>
                      <button
                        type="button"
                        className="btn btn-light navbar-button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {error && (
              <div className="row mb-2 mt-2">
                <div className="alert alert-danger text-center" role="alert">
                  {message}
                </div>
              </div>
            )}
            {updated && (
              <div className="row mb-2 mt-2">
                <div className="alert alert-success text-center" role="alert">
                  {message}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
