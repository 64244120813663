import Header from "../common/Header";
import Footer from "../common/Footer";
import SideNavbar from "./dashboard/SideNavbar";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { getCompanyById, updateCompany } from "../services/Services";

export default function CompanySettingsEdit() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const business_id = localStorage.getItem("business_id");
  const formSchema = Yup.object().shape({
    creditcard_fee_paid_by: Yup.string().nullable().required("Please pick one"),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getCompanyById(business_id, signal)
      .then((res) => {
        if (JSON.stringify(res) !== "{}") {
          setValue("creditcard_fee_paid_by", res.creditcard_fee_paid_by);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [business_id, setValue]);

  const handleEdit = () => {
    setEdit(true);
  };

  const onSubmit = async (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    updateCompany(token, data, business_id, signal)
      .then((res) => {
        if (!res.error) {
          setMessage("Business Information has been updated successfully.");
          setUpdated(true);
          setTimeout(() => {
            reset();
            navigate("/dashboard");
          }, 2000);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };
  return (
    <div className="container-fluid navbar-hero-div">
      <Header />
      <div className="container text-center px-5 py-2 div-bank-info">
        <div className="row row-cols-2 align-items-start g-4 py-3">
          <div className="col-3">
            <SideNavbar />
          </div>
          <div className="offset-md-0 col-9 text-start py-3 px-4 dashboard-bg-border">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form-floating px-2 py-5"
            >
              <div className="row mb-2">
                <div className="col-6 float-start">
                  {/* Bank information */}
                  <h4 className="gifter-details-header2">Settings</h4>
                </div>
                <div className="col-6 text-end">
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    className="text-primary company-profile-edit"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={handleEdit}
                  />
                </div>
              </div>
              {/*  creditcard_fee_paid_by */}
              <div className="row row-cols-3 mb-2">
                <div className="col-5">
                  <label>Credit card processing fee paid by: </label>
                </div>
                <div className="col-3 form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="merchant"
                    disabled={!edit ? "disabled" : ""}
                    {...register("creditcard_fee_paid_by")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Merchant
                  </label>
                </div>
                <div className="col-3 form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="buyer"
                    disabled={!edit ? "disabled" : ""}
                    {...register("creditcard_fee_paid_by")}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Buyer
                  </label>
                </div>
              </div>
              {/* submit */}
              {edit && (
                <div className="row mb-2 pt-3 text-center">
                  <div className="col-12 my-3">
                    <button
                      type="submit"
                      className="btn btn-light navbar-button"
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              )}
              {error && (
                <div className="row mb-2">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              {updated && (
                <div className="row mb-2">
                  <div className="alert alert-success" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
