import { useEffect, useState } from "react";
import {
  getAllGiftCardsByDate,
  getAllTransactionsByBusinessId,
  getRemainingAndRedeemedAmount,
  getAllPayoutsByDate,
} from "../services/Services";

import Header from "../common/Header";
import Footer from "../common/Footer";
import Select from "react-select";
import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  format,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfDay,
  endOfDay,
} from "date-fns";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SideNavbar from "../business/dashboard/SideNavbar";
import GiftcardSales from "./GiftcardSales";
import Redemptions from "./Redemptions";
import Payouts from "./Payouts";

export default function Dashboard() {
  const [giftcardData, setGiftcardData] = useState();
  // eslint-disable-next-line
  const [transData, setTransData] = useState();
  const [payoutData, setPayoutData] = useState();
  const [original, setOriginal] = useState();
  const [remaining, setRemaining] = useState();
  const [redeemed, setRedeemed] = useState();
  const [cc, setCC] = useState();
  const [merchant, setMerchant] = useState();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [duration, setDuration] = useState("1");

  const [optTrans, setOptTrans] = useState("0");

  const [dateSection, setDateSection] = useState(false);
  const [sDate, setSDate] = useState();
  const [eDate, setEDate] = useState();
  const [dateError, setDateError] = useState(false);

  const business_id = localStorage.getItem("business_id");
  const token = localStorage.getItem("token");

  const opt = [
    { value: "0", label: "Gift Card Sales" },
    { value: "1", label: "Redemptions" },
    { value: "2", label: "Payout Details" },
  ];

  const options = [
    { value: "1", label: "Today" },
    { value: "2", label: "This Week" },
    { value: "3", label: "This Month" },
    { value: "4", label: "Custom" },
  ];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    var start_date, end_date;
    if (duration === "1") {
      start_date = format(startOfToday(), "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfToday(), "MM/dd/yyyy HH:mm:ss");
    } else if (duration === "2") {
      start_date = format(startOfWeek(new Date()), "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfWeek(new Date()), "MM/dd/yyyy HH:mm:ss");
    } else if (duration === "3") {
      start_date = format(startOfMonth(new Date()), "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfMonth(new Date()), "MM/dd/yyyy HH:mm:ss");
    } else if (duration === "4") {
      start_date = format(startOfDay(new Date(sDate)), "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfDay(new Date(eDate)), "MM/dd/yyyy HH:mm:ss");
    } else {
      start_date = format(startOfToday, "MM/dd/yyyy HH:mm:ss");
      end_date = format(endOfToday, "MM/dd/yyyy HH:mm:ss");
    }
    getAllGiftCardsByDate(token, business_id, start_date, end_date, signal)
      .then((res) => {
        if (res.length > 0) setGiftcardData(res);
        else setGiftcardData(null);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    getAllTransactionsByBusinessId(
      token,
      business_id,
      start_date,
      end_date,
      signal
    )
      .then((res) => {
        if (res.length > 0) setTransData(res);
        else setTransData(null);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //Payouts
    getAllPayoutsByDate(token, business_id, start_date, end_date, signal)
      .then((res) => {
        if (res.length > 0) setPayoutData(res);
        else setPayoutData(null);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    getRemainingAndRedeemedAmount(
      token,
      business_id,
      start_date,
      end_date,
      signal
    )
      .then((res1) => {
        setOriginal(res1.original_amt);
        setRemaining(res1.remaining_amt);
        setRedeemed(res1.redeemed_amount);
        setCC(res1.cc_fee_amt);
        setMerchant(res1.merchant_value_amt);
        var tmp_chart_data = [];
        tmp_chart_data.push(["Type", "Amount"]);
        var remain_val = (res1.remaining_amt % res1.original_amt) * 100;
        var redeem_val = (res1.redeemed_amount % res1.original_amt) * 100;
        tmp_chart_data.push([
          "Remaining Amount",
          remain_val,
          // remain_val === "0" ? null : remain_val,
        ]);
        tmp_chart_data.push([
          "Redeemed Amount",
          redeem_val,
          // redeem_val === "0" ? null : redeem_val,
        ]);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [business_id, token, duration, sDate, eDate]);

  const handleCustom = () => {
    if (
      sDate === null ||
      sDate === undefined ||
      eDate === null ||
      eDate === undefined
    ) {
      setDateError(true);
    } else {
      setDateError(false);
      setDuration("4");
    }
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container text-center px-5 py-2 div-bank-info">
          <div className="row align-items-start g-4 py-3">
            <div className="col-3">
              <SideNavbar />
            </div>
            <div className="col-9">
              <div className="row align-items-center g-4 mb-3">
                <div className="col-md-4 text-start ">
                  <Select
                    options={opt}
                    defaultValue={opt[optTrans]}
                    onChange={(e) => {
                      setOptTrans(e.value);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      width: 20,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: "#ef9c39",
                        backgroundColor: "white",
                      }),
                      option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        color: "black",
                        backgroundColor: isSelected
                          ? "white"
                          : isFocused
                          ? "#FEF0FA"
                          : "white",
                        cursor: "default",
                      }),
                    }}
                  />
                </div>
                <div className="col-5 offset1-md-4 col-md-2 text-start">
                  {dateSection && (
                    <DatePicker
                      className="form-control"
                      placeholderText="Start Date"
                      selectsStart
                      selected={sDate}
                      onChange={(date) => setSDate(date)}
                      startDate={sDate}
                      showIcon
                      isClearable
                      toggleCalendarOnIconClick
                    />
                  )}
                </div>
                <div className="col-5 col-md-2 text-start">
                  {dateSection && (
                    <DatePicker
                      className="form-control"
                      placeholderText="End Date"
                      selectsEnd
                      selected={eDate}
                      onChange={(date) => setEDate(date)}
                      endDate={eDate}
                      startDate={sDate}
                      minDate={sDate}
                      showIcon
                      isClearable
                      toggleCalendarOnIconClick
                    />
                  )}
                </div>
                <div className="col-2 col-md-1 text-start">
                  {dateSection && (
                    <>
                      <button
                        className="btn btn-light navbar-button-tiny"
                        onClick={handleCustom}
                      >
                        Go
                      </button>
                    </>
                  )}
                </div>
                <div className="col-12 col-md-3 text-start">
                  <Select
                    options={options}
                    defaultValue={options[0]}
                    onChange={(e) => {
                      if (e.value !== "4") {
                        setDateSection(false);
                        setSDate("");
                        setEDate("");
                        setDuration(e.value);
                      } else setDateSection(true);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      width: 20,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: "#ef9c39",
                        backgroundColor: "white",
                      }),
                      option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        color: "black",
                        backgroundColor: isSelected
                          ? "white"
                          : isFocused
                          ? "#FEF0FA"
                          : "white",
                        cursor: "default",
                      }),
                    }}
                  />
                </div>
                {dateError && (
                  <div className="row align-items-start text-start mb-3">
                    <div className="col-5 offset-md-4 col-md-5 text-center">
                      <span className="span-errors" role="alert">
                        Please provide valid date range
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {optTrans === "0" && (
                <GiftcardSales
                  giftcardData={giftcardData}
                  error={error}
                  message={message}
                  // original={original}
                  // cc={cc}
                  // merchant={merchant}
                />
              )}
              {optTrans === "1" && (
                <Redemptions
                  giftcardData={giftcardData}
                  error={error}
                  message={message}
                  // redeemed={redeemed}
                  // remaining={remaining}
                  // merchant={merchant}
                />
              )}
              {optTrans === "2" && (
                <Payouts data={payoutData} error={error} message={message} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
