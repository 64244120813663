import Footer from "./Footer";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

export default function NotFound404() {
  const navigate = useNavigate();
  const logged_in = localStorage.getItem("logged_in");

  if (logged_in === undefined || logged_in === null) {
    window.location.href = "/login";
  }

  const handleClick = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        {/* {logged_in ? <SubNavbar /> : <Header />} */}
        <Header />
        <div className="d-flex align-items-center justify-content-center div-bank-info">
          <div className="text-center">
            <h1 className="display-1 fw-bold">404</h1>
            <p className="fs-3">
              {" "}
              <span className="text-danger">Opps!</span> Page not found.
            </p>
            <p className="lead">The page you’re looking for doesn’t exist.</p>
            {logged_in ? (
              // <a href="/dashboard" className="btn btn-light btn-sm navbar-button">
              //   Go Home
              // </a>
              <button
                className="btn btn-light btn-sm navbar-button navbar-button"
                type="button"
                onClick={handleClick}
              >
                Home
              </button>
            ) : (
              <a href="/" className="btn btn-light btn-sm navbar-button">
                Go Home
              </a>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
