import { useForm } from "react-hook-form";
import { useState } from "react";
import Header from "../common/Header";
import { forgotPassword } from "../services/Services";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Footer from "../common/Footer";

export default function ForgotPassword() {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [result, setResult] = useState();

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Must be a valid email"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;
    forgotPassword(data, signal)
      .then((res) => {
        if (res.message) {
          setResult(res.message);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup
    return () => controller.abort();
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container text-center px-5 py-5 div-bank-info">
          <div className="row row-cols-2 align-items-center g-4 py-3">
            <div className="offset-md-4 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-start">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-2">
                  <h4 className="gifter-details-header2">Forgot Password</h4>
                </div>
                {/*  email address */}
                <div
                  className={
                    "row form-floating " + (errors.email ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="email"
                    placeholder="Email"
                    className={
                      "form-control " +
                      (errors.company_name ? "is-invalid" : "")
                    }
                    {...register("email")}
                  />
                  <label htmlFor="email">Email *</label>
                </div>
                {errors.email?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.email.message}
                    </span>
                  </div>
                )}

                {/* submit */}
                <div className="row mb-2 text-center">
                  <div className="col-12 my-3">
                    <button
                      type="submit"
                      className="btn btn-light navbar-button"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                )}
                {result && (
                  <div className="alert alert-success" role="alert">
                    {result}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
