import Header from "./Header";
import Footer from "./Footer";
export default function PrivacyPolicy() {
  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container col-xxl-12 col-lg-12 col-md-12 col-12 px-4 py-3 div-bank-info">
          <div className="row align-items-start">
            <div className="offset-1 col-10">
              <div className="mx-auto p-2">
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Privacy Policy
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  This policy outlines the practices employed by Princeton Logic
                  LLC., and its affiliates ("Princeton Logic," "Gifuto," "we,"
                  or "us") regarding the handling of your information through
                  the Services (as defined below). Our aim is to provide
                  transparency on how we collect, store, utilize, and protect
                  your personal data.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  The information we gather is intended to support our
                  relationship with you, enhance our services, and convey
                  promotions or special services. We will not disclose your
                  information to any third party, except as explicitly described
                  in this Privacy Policy. Your use of Gifuto website constitutes
                  your agreement to this policy.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Throughout this Privacy Policy, the term "Service"
                  collectively refers to our website, software, and services.
                  It's important to note that this Privacy Policy is not
                  applicable to information collected through means other than
                  your usage of the Service (including offline) or from
                  alternative sources.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Any Links to third-party websites are provided solely for your
                  convenience; when you go to those sites, your experience there
                  is subject to the privacy notices and terms of use of those
                  third-party sites, not ours, unless you are explicitly told
                  otherwise.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Information We Collect
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Registering Gifuto Account: When merchant registers Gifuto
                  account, we collect information about your business and your
                  business name, your name, address, phone number, email
                  address, bank information and other related information.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Purchasing eGift cards: When you purchase eGift card, we
                  collect payment and contact information about you. We collect
                  your name, phone number, email address, personal message to
                  the recipient and your credit card information or other
                  payment details along with your billing address. In addition,
                  we collect information about the intended recipient of the
                  eGift card.{" "}
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Participating in a Contest, Promotion, or Survey: Periodically
                  Gifuto may invite you to participate in a contest, promotion,
                  or survey. If you choose to participate, we will collect and
                  retain your answer to any survey questions, what promotions
                  you participate in, and the results from any contest. The data
                  from any contest, promotion, or survey that you participate in
                  may be associated with you personally.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Technical Information
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Like most websites, our servers automatically collect certain
                  technical information when you visit our site, including your
                  Internet Protocol (“IP”) address, browser type, browser
                  language, the date and time of your visit, the referring URL,
                  and, if you have visited our website before, one or more
                  cookies that may uniquely identify your browser. We also
                  collect information about your activities on the site, such as
                  the pages you access.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Like most websites, We use cookies, web beacons, embedded
                  scripts, and similar technologies to collect information about
                  how you use the Site.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  We automatically collect certain technical information when
                  you visit our site, including your Internet Protocol (“IP”)
                  address, browser type, browser language, the date and time of
                  your visit, the referring URL, and, if you have visited our
                  website before, one or more cookies that may uniquely identify
                  your browser. We also collect information about your
                  activities on the site, such as the pages you access.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  One way we collect this information is through “cookies.” A
                  “cookie” is a small file containing a string of characters
                  that is sent to your computer when you visit a website. When
                  you visit the website again, the cookie allows that site to
                  recognize your browser. Cookies may store user preferences and
                  other information. You can set your browser to refuse all
                  cookies or to alert you when a cookie is being sent. Note,
                  however, that some website features or services may not
                  function properly without cookies.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  We use web beacon or similar technologies to collect certain
                  information. A web beacon is a technique used on web pages and
                  email to unobtrusively allow checking that a user has accessed
                  some content.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  We collect data through log files. Your browser automatically
                  reports log file information each time you visit a web page on
                  our site. Upon registering or viewing our site, our servers
                  automatically capture specific details that your web browser
                  transmits during your website visits. These server logs may
                  encompass details like your web request, IP address, browser
                  type, referring/exit pages and URLs, number of clicks, domain
                  names, landing pages, pages viewed, and similar information.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Your Choices
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  You have the option to choose not to provide personally
                  identifiable information through the Service. However, if you
                  decline to do so, Gifuto may be unable to offer specific
                  services to you or any service at all. You have the
                  flexibility to update or rectify your account information by
                  logging in to your account at any time. To review and correct
                  the information Gifuto holds about you, you can contact us
                  using the details provided below. It's important to note that
                  a request for information correction doesn't obligate us to
                  take any action unless required by law, nor does it impose any
                  liability on us.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Personal Information Protection
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  "Personal Information" refers to information uniquely
                  associated with you that can be used to identify an
                  individual. This excludes data that has been aggregated or
                  anonymized. We take measures to safeguard this information,
                  implementing procedures and safeguards in compliance with
                  State and Federal laws and regulations.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  The protection of your Personal Information is a shared
                  responsibility. It is crucial that you ensure the security and
                  confidentiality of your account numbers, passwords, security
                  questions and answers, login details, and any other access
                  information used to utilize or access the Service. You are
                  accountable for preventing unauthorized access to and use of
                  your information or data, including Account Data stored in or
                  by the Service. All electronic communications containing
                  Account Data sent to us or any third party are assumed to have
                  been sent by you. Should you become aware of any loss, theft,
                  or unauthorized use of Account Data, it is imperative to
                  notify us immediately. We reserve the right to deny access to
                  the Service, either in part or in full, if we suspect any
                  loss, theft, or unauthorized use of Account Data or access
                  information.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  How We Use Personal Information
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  We utilize your Personal Information to furnish you with the
                  features and functionalities of our Service, and may share it
                  with trusted third parties to ensure a secure,
                  high-performance experience. Your engagement with the Service,
                  including activities like giving or redeeming a gift, leaving
                  comments, reaching out to customer service, or seeking
                  technical support, prompts the application of the collected
                  information. This data enables us to verify your identity,
                  communicate effectively, and enforce agreements, contributing
                  to an optimal experience for all Gifuto users by ensuring
                  compliance with State and Federal laws and our internal
                  policies. Furthermore, we utilize this information to assess
                  user interactions with the Service, aiming to enhance and
                  improve our offerings.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Gifuto may share specific non-identifying information about
                  you with third parties for purposes such as analyzing Service
                  usage, diagnosing technical issues, maintaining security, and
                  tailoring content.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Additionally, we employ cookies and log file information to:
                  (a) retain information to eliminate the need for re-entry
                  during your current or subsequent visits; (b) provide
                  personalized content; (c) assess the effectiveness of our
                  Service; (d) track aggregate metrics like total visitors and
                  traffic; (e) diagnose and resolve technology issues associated
                  with certain IP addresses reported by users or engineers; and
                  (f) assist you in efficiently accessing your information upon
                  signing in.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Disclosure of Personal Information
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Gifuto is committed to not renting or selling your Personal
                  Information to external parties. However, Gifuto may
                  collaborate with third parties to deliver services to you, and
                  in such instances, the use of your Personal Information by
                  these third parties will be constrained by terms at least as
                  stringent as outlined in this Privacy Policy. It's important
                  to note that personal information may be stored in locations
                  beyond Gifuto's direct control, such as on servers or
                  databases co-located with hosting providers.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Our service may necessitate sharing your personal information
                  with third parties, including, but not limited to:
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Service Providers: These entities offer crucial operational
                  services, covering fraud prevention, transaction processing,
                  collections, direct marketing, and managed technology
                  services. Our contractual agreements stipulate that these
                  service providers use your information solely for the services
                  they perform on our behalf and not for their own benefit.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Financial Institutions: Collaboration with financial
                  institutions enables us to provide payment processing services
                  and partner with them to develop new offerings for you and
                  other Gifuto users.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Fellow Gifuto Users: Your personal information may be shared
                  with other Gifuto users to facilitate the exchange of gifts
                  between you and them.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  For Merchants
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Law enforcement authorities or government representatives may
                  require us to share information to comply with court orders
                  and other legal mandates. We may also disclose information
                  when we believe it is necessary to report suspicious
                  activities, prevent physical harm, financial loss, or
                  violations of our agreements and policies.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Additionally, your Personal Information may be shared with
                  other third parties under the following circumstances:
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  With Your Consent: Disclosure to third parties will be subject
                  to your prior consent or direction.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Business Transactions: In the course of developing our
                  business, we may buy or sell assets or business offerings.
                  Customer, member, transaction, email, and visitor information
                  is typically one of the transferred business assets in such
                  transactions, including corporate divestitures, mergers, or
                  dissolution.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Events and Activities: During contests, special offers, or
                  other events ("Events") on the Service with third-party
                  partners, if you provide information to these third parties,
                  you grant them permission to use it for the purpose of that
                  Event and any other approved use. Gifuto cannot control the
                  third parties' use of your information, and if you choose not
                  to participate in these Events, your information will not be
                  collected or shared.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Legal Compliance and Protection: Except as otherwise described
                  in this Privacy Policy, Gifuto will not disclose Personal
                  Information to third parties unless required by law, subpoena,
                  or if deemed necessary to (a) conform to the law, comply with
                  legal processes, or investigate, prevent, or take action
                  regarding suspected or actual illegal activities; (b) enforce
                  our Terms and Conditions, take precautions against liability,
                  investigate and defend against third-party claims or
                  allegations, assist government enforcement agencies, or
                  protect the security or integrity of our site; and (c)
                  exercise or protect the rights, property, or personal safety
                  of Gifuto, our users, or others.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Aggregated Information: We may share aggregated demographic
                  and statistical information with our partners, which is not
                  linked to any personal information identifying individual
                  persons.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Compromise of Personal Information
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  If there is a breach of security leading to the compromise of
                  personal information, Gifuto is committed to promptly
                  notifying the individuals affected. This notification will be
                  conducted in accordance with the procedures outlined in this
                  Privacy Policy or as otherwise mandated by applicable law.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Children's Privacy
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Ensuring the privacy of young children is of utmost importance
                  to Gifuto. Consequently, we do not knowingly collect or
                  request personal information from individuals under the age of
                  13, nor do we knowingly permit such individuals to register
                  with our Service. If you are under 13 years old, we kindly
                  request that you refrain from providing any information about
                  yourself to us, including your name, address, telephone
                  number, or email address. Gifuto strictly prohibits anyone
                  under the age of 13 from submitting any personal information
                  through our platform.
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  In the event that we become aware of the inadvertent
                  collection of personal information from a child under the age
                  of 13 without parental consent, we will promptly delete such
                  information. If you believe that we may have information from
                  or about a child under the age of 13, please contact us using
                  the information provided below.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Links to Other Web Sites
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  Gifuto disclaims responsibility for the practices employed by
                  websites linked to or from our website, as well as for the
                  information or content contained within them. It is important
                  to note that when you follow a link from our website to
                  another, our Privacy Policy no longer applies. Your activities
                  and interactions on any external website, even those linked
                  from our site, are governed by the rules and policies of that
                  specific website. We strongly recommend reviewing the policies
                  of other websites before providing any personal information on
                  those platforms.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Notification Procedures
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  It is our policy to provide notifications, whether such
                  notifications are required by law or are for marketing or
                  other business related purposes. Notifications may be sent to
                  you through the email address you provided during Service
                  registration, through displayed posts on our website, or via
                  your Gifuto account. The method of notice chosen in each
                  situation will be at our sole discretion.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">
                  Policy Changes
                </p>
                <p className="fs-6 fw-normal text-start pb-0">
                  We regularly review our privacy policy to ensure it aligns
                  with the privacy interests of our users. If there are
                  substantial changes to our information practices, we will
                  notify you by posting the updated Privacy Policy at &nbsp;
                  <a href="/privacy" target="_blank">
                    www.gifuto.io/privacy
                  </a>
                  . We encourage you to check our website periodically for the
                  latest information on how we utilize the data we collect.
                  Changes to this Privacy Policy become effective when posted on
                  this page.
                </p>
                <p className="fs-6 fw-bold text-start pb-1 mt-1">Contact Us</p>
                <p className="fs-6 fw-normal text-start pb-0">
                  You may contact us by using the “Contact Us” link located in
                  the footer of our site. We strive to respond promptly to every
                  message we receive. The information you submit through the
                  “Contact Us” link is utilized exclusively to address your
                  inquiries or comments. Additionally, we may retain and share
                  your comments with our customer care team for the purpose of
                  enhancing our service in the future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
