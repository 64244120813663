import { useParams } from "react-router-dom";
import Header from "../common/Header";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  getGiftCardById,
  redeemGiftCard,
  getAllTransactions,
  getFormattedDate,
} from "../services/Services";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Footer from "../common/Footer";

export default function GiftCardRedeem() {
  let { giftcard_id } = useParams();
  const [giftcardCode, setGiftcardCode] = useState();
  const [maskedCode, setMaskedCode] = useState();
  const [gcamt, setGCAmt] = useState();
  const [remainingAmt, setRemainingAmt] = useState();
  const [redeemed, setRedeemed] = useState();
  const [transData, setTransData] = useState();
  const token = localStorage.getItem("token");
  const business_id = localStorage.getItem("business_id");
  const logged_in = localStorage.getItem("logged_in");

  const [error, setError] = useState(false);
  const [message, setMessage] = useState();

  if (logged_in === undefined || logged_in === null) {
    window.location.href = "/login";
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (giftcard_id) {
      getGiftCardById(giftcard_id, token, signal)
        .then((res) => {
          if (business_id === res.business_id) {
            setGiftcardCode(res.code);
            var code = res.code;
            setMaskedCode("xxxx" + code.substring(14 + 1));
            setRemainingAmt(res.remaining_amount);
          } else {
            setMessage(
              "You are trying to redeem gift card that doesn't exist in the system. Please provide valid gift card."
            );
            setError(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setError(true);
        });
    }
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [business_id, giftcard_id, token]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (giftcardCode) {
      getAllTransactions(giftcardCode, token, signal)
        .then((res) => {
          if (res.length > 0) setTransData(res);
        })
        .catch((err) => {
          setMessage(err.message);
          setError(true);
        });
    }
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [giftcardCode, remainingAmt, token]);

  const formSchema = Yup.object().shape(
    {
      amount: Yup.number()
        .test("chk-decimal", "Please provide valid decimals", (value) =>
          (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/)
        )
        .required("Amount is required")
        .typeError("Amount must be valid")
        .min("0.01")
        .max(remainingAmt),
    },
    [["amount", "amount"]]
  );

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setGCAmt(data["amount"]);
    setRedeemed(false);
    setError(false);
    redeemGiftCard(giftcard_id, data, token, signal)
      .then((res) => {
        if (!res.error) {
          setRemainingAmt(res.remaining_amount);
          setRedeemed(true);
          setTimeout(() => {
            setRedeemed(false);
          }, 3000);
          reset();
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        {!error ? (
          <div className="container col-xxl-4 col-lg-4 col-md-6 col-12 px-4 py-3 div-company-info">
            <div className="col-12 text-start align-items-center">
              <div className="row mb-2">
                <div className="text-center">
                  <h2 className="gifter-details-header1 my-3">
                    Redeem GiftCard
                  </h2>
                  <p className="p-1">
                    Gift Card Code:{" "}
                    <span className="fw-semibold fs-4">{maskedCode}</span>
                  </p>
                  <p className="p-1">
                    Remaining Amount:{" "}
                    <span className="fw-bold">$ {remainingAmt}</span>
                  </p>
                </div>
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className="form-floating">
                {/* amount */}
                <div
                  className={
                    "row form-floating " + (errors.amount ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="amount"
                    disabled={remainingAmt <= 0 ? true : false}
                    className={
                      "form-control " + (errors.amount ? "is-invalid" : "")
                    }
                    {...register("amount")}
                  />
                  <label htmlFor="amount">Amount To Redeem($) *</label>
                </div>
                {errors.amount?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.amount.message}
                    </span>
                  </div>
                )}

                {/* submit */}
                <div className="row text-center">
                  <div className="col-12 my-3 ">
                    <button
                      type="submit"
                      className="btn btn-light btn-sm px-4 me-md-2 navbar-button"
                    >
                      Redeem
                    </button>
                  </div>
                </div>
                {error && (
                  <div className="row mb-2">
                    <div
                      className="alert alert-danger text-center"
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                )}
                {redeemed && (
                  <div className="row mb-2">
                    <div
                      className="alert alert-success text-center"
                      role="alert"
                    >
                      <p className="pt-2">
                        Gift amount ${gcamt} has been redeemed
                      </p>
                    </div>
                  </div>
                )}
              </form>
              <div className="table-responsive mb-5">
                <table className="table text-center align-middle table-hover mb-0 caption-top">
                  <caption>List of Transactions</caption>
                  <thead>
                    <tr>
                      <th scope="col">Redeemed Date</th>
                      <th scope="col">Redeemed By</th>
                      <th scope="col">Redeemed Amount($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transData &&
                      transData.map((transaction, index) => (
                        <tr key={index}>
                          <td>{getFormattedDate(transaction.created_at)}</td>
                          <td>{transaction.redeemed_by_user_name}</td>
                          <td>{transaction.redeemed_amount}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="container col-xxl-4 col-lg-4 col-md-6 col-12 px-4 py-3 div-company-info">
            <div className="col-12 text-start align-items-center">
              <div className="row mb-2">
                <div className="text-center">
                  <h2 className="gifter-details-header1 my-5">
                    Redeem GiftCard
                  </h2>
                  <div className="alert alert-danger" role="alert">
                    {message === "NOT FOUND" || message === "Failed to fetch"
                      ? "You are trying to redeem gift card that doesn't exist in the system. Please contact Gifuto team for further assistance."
                      : message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
