import { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { updateGiftcard } from "../services/Services";

export default function CCPaymentSuccess() {
  let { business_id, gifter_id, giftee_id, giftcard_id } = useParams();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [message, setMessage] = useState();

  const payment_intent = queryParams.get("payment_intent");
  const redirect_status = queryParams.get("redirect_status");

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (redirect_status === "succeeded") {
      //make database call
      const data = {};
      data["status"] = redirect_status;
      data["payment_intent"] = payment_intent;
      data["business_id"] = business_id;
      data["gifter_id"] = gifter_id;
      data["giftee_id"] = giftee_id;
      updateGiftcard(giftcard_id, data, signal)
        .then((res) => {
          if (!res.error) {
            navigate("/payment-confirmation/" + giftcard_id);
          } else if (res.error) {
            setMessage(res.error);
            setError(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setError(true);
        });
    } else {
      setError(true);
    }
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [
    redirect_status,
    payment_intent,
    business_id,
    gifter_id,
    giftee_id,
    giftcard_id,
    navigate,
  ]);

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container col-xxl-4 col-lg-4 col-md-6 col-12 px-4 py-3 div-bank-info">
          <div className="row align-items-center g-4 py-3">
            <div className="text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              )}
              {!error && (
                <div className="text-center">
                  <div className="spinner-border" role="status"></div>
                  <div className="pt-3">
                    Please don't refresh the page and wait to for the payment
                    process to be completed...
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
