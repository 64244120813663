import { useNavigate } from "react-router-dom";
export default function Hero() {
  const navigate = useNavigate();
  const onclick = () => {
    navigate("/learn-more");
  };
  return (
    <div className="container">
      <div className="row row-cols-2 align-items-center px-5 py-0">
        <div className="col-12 col-md-6">
          <p className="fs-4 fw-semibold text-start pb-0 hero-medium">
            elevate the experience of selling <br />
            gift cards by Gifuto’s
          </p>
          <p className="fs-1 fw-bolder text-start pb-3 hero-heading">
            <span className="lower-case">e</span>Gift Card Platform!
          </p>
          <p className="hero-sub-text fs-6 text-start">
            GIFUTO offers a comprehensive e-Gift solution specifically designed
            to cater to the needs of small, locally owned businesses,
            independent service providers, and freelancers. Recognizing the
            challenges these entities face in navigating the digital landscape,
            GIFUTO offers a single, streamlined platform to manage all aspects
            of their electronic gift card operations. By providing a
            user-friendly and comprehensive solution, GIFUTO equips small
            businesses and freelancers with the means to leverage the benefits
            of digital gifting, enhancing customer engagement and driving
            revenue growth in today's digital-centric marketplace.
          </p>
          <p className="text-start my-5">
            <button
              className="btn btn-light btn-sm navbar-button"
              onClick={onclick}
              type="submit"
            >
              LEARN MORE
            </button>
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img
            src="img/lady-giftcard-transparent.png"
            className="d-block mx-lg-auto img-fluid landing-logo-hero"
            alt="Lady With Card"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}
