import Header from "./common/Header";
import Footer from "./common/Footer";
import Search from "./business/search/Search";
import Hero from "./Hero";
import Discount from "./Discount";
import About from "./About";
import HowItWorks from "./HowItWorks";

function LandingPage() {
  return (
    <>
      <section id="home">
        <div className="container-fluid navbar-hero-div">
          <Header />
          <Hero />
        </div>
      </section>
      <Discount />
      <section id="about">
        <About />
      </section>
      <section id="how-it-works">
        <HowItWorks />
      </section>
      <section id="gift-card">
        <Search />
      </section>
      <Footer />
    </>
  );
}
export default LandingPage;
