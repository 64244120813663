import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  // LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { payment_success_url, getGiftCard } from "../services/Services";
import Header from "../common/Header";
import Footer from "../common/Footer";

export default function CheckoutForm({
  business_id,
  gifter_id,
  giftee_id,
  giftcard_id,
  email,
}) {
  const stripe = useStripe();
  const elements = useElements();

  //const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [amt, setAmt] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [CCFee, setCCFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [ccPaidBy, setCCPaidBy] = useState();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (giftcard_id) {
      getGiftCard(giftcard_id, signal)
        .then((res) => {
          setAmt(res.original_amount);
          setServiceFee(res.service_fee);
          setCCFee(res.credit_card_fee);
          setTotal(res.total_charged_amount);
          setCCPaidBy(res.creditcard_fee_paid_by);
        })
        .catch((err) => {
          setMessage(err.message);
        });
    }
    //cleanup
    return () => controller.abort();
  }, [giftcard_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          payment_success_url +
          business_id +
          "/" +
          gifter_id +
          "/" +
          giftee_id +
          "/" +
          giftcard_id,
        receipt_email: email,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container col-xxl-4 col-lg-4 col-md-6 col-12 px-2 pt-5">
          <div className="fee-details">
            <div className="row align-items-center g-0 py-0">
              <div className="col-6 text-end">Gift Card Amount:</div>
              <div className="col-2 text-end ps-2">${amt}</div>
              {/* <div className="col-6 text-end">Service Fee:</div>
              <div className="col-2 text-end ps-2">${serviceFee}</div> */}

              {ccPaidBy !== "merchant" && (
                <>
                  <div className="col-6 text-end">Credit Card Fee:</div>
                  <div className="col-2 text-end ps-2">${CCFee}</div>
                </>
              )}
              <div className="col-6 text-end">Total Amount:</div>
              <div className="col-2 text-end ps-2">${total}</div>
            </div>
          </div>
        </div>
        <div className="container col-xxl-4 col-lg-4 col-md-6 col-12 px-2 pb-5 div-bank-info">
          <div className="row align-items-center g-0 py-3">
            <form id="payment-form" onSubmit={handleSubmit}>
              {/* <LinkAuthenticationElement
                id="link-authentication-element"
                onChange={(e) => setEmail(e.target.value)}
              /> */}
              <PaymentElement
                id="payment-element"
                options={paymentElementOptions}
              />
              <button
                id="stripe-button"
                disabled={isLoading || !stripe || !elements}
                style={{ backgroundColor: "#ee9b3c", color: "white" }}
                className="btn btn-light navbar-button"
              >
                <span id="button-text">
                  {isLoading ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    "BUY GIFT CARD"
                  )}
                </span>
              </button>
              {/* Show any error or success messages */}
              {message && <div id="payment-message">{message}</div>}
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
