import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCompanyById,
  updateCompany,
  gifter_site_url,
} from "../services/Services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Header from "../common/Header";
import Footer from "../common/Footer";
import SideNavbar from "./dashboard/SideNavbar";

function CompanyBankEdit() {
  const navigate = useNavigate();
  const cheque = gifter_site_url + "/img/cheque.png";
  const token = localStorage.getItem("token");
  const business_id = localStorage.getItem("business_id");

  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [edit, setEdit] = useState(false);

  const formSchema = Yup.object().shape({
    bank_name: Yup.string().nullable().required("Bank name is required"),
    bank_routing_number: Yup.string()
      .nullable()
      .required("Routing number is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(9, "Must be exactly 9 digits")
      .max(9, "Must be exactly 9 digits"),
    confirm_bank_routing_number: Yup.string().oneOf(
      [Yup.ref("bank_routing_number")],
      "Routing numbers does not match"
    ),
    bank_account_number: Yup.string()
      .nullable()
      .required("Account number is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(9, "Must be between 9 to 18 digits")
      .max(18, "Must be between 9 to 18 digits"),
    confirm_bank_account_number: Yup.string().oneOf(
      [Yup.ref("bank_account_number")],
      "Account numbers does not match"
    ),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  const isNullOrUndefined = (val) => {
    return val === undefined || val === null;
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getCompanyById(business_id, signal)
      .then((res) => {
        if (JSON.stringify(res) !== "{}") {
          setValue("bank_name", res.bank_name);
          setValue("bank_routing_number", res.bank_routing_number);
          setValue("bank_account_number", res.bank_account_number);
          if (
            isNullOrUndefined(res.bank_name) &&
            isNullOrUndefined(res.bank_routing_number) &&
            isNullOrUndefined(res.bank_account_number)
          ) {
            setEdit(true);
          }
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  }, [business_id, setValue]);

  const onSubmit = async (data, e) => {
    delete data["confirm_bank_routing_number"];
    delete data["confirm_bank_account_number"];

    const controller = new AbortController();
    const signal = controller.signal;

    updateCompany(token, data, business_id, signal)
      .then((res) => {
        if (!res.error) {
          setMessage("Business Information has been updated successfully.");
          setUpdated(true);
          setTimeout(() => {
            reset();
            navigate("/dashboard");
          }, 2000);
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };

  const handleEdit = () => {
    setEdit(true);
  };

  return (
    <div className="container-fluid navbar-hero-div">
      <Header />
      <div className="container text-center px-5 py-2 div-bank-info">
        <div className="row row-cols-2 align-items-start g-4 py-3">
          <div className="col-3">
            <SideNavbar />
          </div>
          <div className="offset-md-0 col-9 text-start py-3 px-4 dashboard-bg-border">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form-floating px-5 py-5"
            >
              <div className="row mb-2">
                <div className="col-6 float-start">
                  {/* Bank information */}
                  <h4 className="gifter-details-header2">Bank information</h4>
                </div>
                <div className="col-6 text-end">
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    className="text-primary company-profile-edit"
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={handleEdit}
                  />
                </div>
              </div>
              {/* bank name */}
              <div
                className={
                  "row form-floating " + (errors.bank_name ? "" : "mb-3")
                }
              >
                <input
                  type="text"
                  id="bank_name"
                  placeholder="Bank Name"
                  disabled={!edit ? "disabled" : ""}
                  className={
                    "form-control " + (errors.bank_name ? "is-invalid" : "")
                  }
                  {...register("bank_name")}
                />
                <label htmlFor="bank_name">Bank Name *</label>
              </div>
              {errors.bank_name?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.bank_name.message}
                  </span>
                </div>
              )}
              {/* routing number */}
              <div
                className={
                  "row form-floating " +
                  (errors.bank_routing_number ? "" : "mb-3")
                }
              >
                <input
                  type="text"
                  id="bank_routing_number"
                  placeholder="Routing Number"
                  disabled={!edit ? "disabled" : ""}
                  className={
                    "form-control " +
                    (errors.bank_routing_number ? "is-invalid" : "")
                  }
                  {...register("bank_routing_number")}
                />
                <label htmlFor="bank_routing_number">Routing Number *</label>
              </div>
              {errors.bank_routing_number?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.bank_routing_number.message}
                  </span>
                </div>
              )}
              {/* confirm rounting number */}
              {edit && (
                <div
                  className={
                    "row form-floating " +
                    (errors.confirm_bank_routing_number ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="confirm_bank_routing_number"
                    placeholder="Confirm Routing Number"
                    className={
                      "form-control " +
                      (errors.confirm_bank_routing_number ? "is-invalid" : "")
                    }
                    {...register("confirm_bank_routing_number")}
                  />
                  <label htmlFor="confirm_bank_routing_number">
                    Confirm Routing Number *
                  </label>
                </div>
              )}
              {errors.confirm_bank_routing_number?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.confirm_bank_routing_number.message}
                  </span>
                </div>
              )}
              {/* account number */}
              <div
                className={
                  "row form-floating " +
                  (errors.bank_account_number ? "" : "mb-3")
                }
              >
                <input
                  type="text"
                  id="bank_account_number"
                  placeholder="Account Number"
                  disabled={!edit ? "disabled" : ""}
                  className={
                    "form-control " +
                    (errors.bank_account_number ? "is-invalid" : "")
                  }
                  {...register("bank_account_number")}
                />
                <label htmlFor="bank_account_number">Account Number *</label>
              </div>
              {errors.bank_account_number?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.bank_account_number.message}
                  </span>
                </div>
              )}
              {/* confirm account number */}
              {edit && (
                <div
                  className={
                    "row form-floating " +
                    (errors.confirm_bank_account_number ? "" : "mb-3")
                  }
                >
                  <input
                    type="text"
                    id="confirm_bank_account_number"
                    placeholder="Confirm Account Number"
                    className={
                      "form-control " +
                      (errors.confirm_bank_account_number ? "is-invalid" : "")
                    }
                    {...register("confirm_bank_account_number")}
                  />
                  <label htmlFor="confirm_bank_account_number">
                    Confirm Account Number *
                  </label>
                </div>
              )}
              {errors.confirm_bank_account_number?.message && (
                <div className="row align-items-start text-start mb-3">
                  <span className="span-errors" role="alert">
                    {errors.confirm_bank_account_number.message}
                  </span>
                </div>
              )}

              <div className="row mb-2 text-start">
                <div
                  className="col-12 fst-italic"
                  style={{ fontSize: "0.75rem" }}
                >
                  Note: EIN number is required to process the payments. Please
                  update the EIN number in the profile section.
                </div>
              </div>

              <div className="row mb-2 text-center">
                <div className="col-6">
                  <img
                    src={cheque}
                    alt="cheque-img"
                    className="d-inline-block align-text-top img-fluid"
                  />
                </div>
              </div>

              {/* submit */}
              {edit && (
                <div className="row mb-2 pt-3 text-center">
                  <div className="col-12 my-3">
                    <button
                      type="submit"
                      className="btn btn-light navbar-button"
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              )}
              {error && (
                <div className="row mb-2">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              {updated && (
                <div className="row mb-2">
                  <div className="alert alert-success" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default CompanyBankEdit;
