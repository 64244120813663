import { getFormattedDate } from "../services/Services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
export default function CompanyUserRow({ user, handleUpdate, handleDelete }) {
  return (
    <tr key={user.id}>
      <td>{user.primary_contact}</td>
      <td>{user.email}</td>
      <td>{user.phone}</td>
      <td>{getFormattedDate(user.created_at)}</td>
      <td>{user.role === "2" ? "Admin" : "User"}</td>
      <td>{user.is_active === "t" ? "Active" : "Inactive"}</td>
      <td>
        {user.role === "3" && (
          <FontAwesomeIcon
            icon={faEdit}
            style={{
              cursor: "pointer",
              // marginLeft: "5px",
              // marginRight: "5px",
              color: "black",
            }}
            onClick={() => handleUpdate(user.id)}
            id={user.id}
          />
        )}
      </td>
      <td>
        {user.role === "3" && (
          <FontAwesomeIcon
            icon={faTrashCan}
            style={{
              cursor: "pointer",
              // marginLeft: "5px",
              color: "red",
            }}
            onClick={() => handleDelete(user.id)}
            id={user.id}
          />
        )}
      </td>
    </tr>
  );
}
