export default function Discount() {
  return (
    <div
      className="container discount-div text-center px-md-5 py5"
      style={{ paddingTop: "13rem", paddingBottom: "2rem" }}
    >
      <div
        className="px-md-5 mt-1 mb-5"
        style={{ marginTop: "-6rem", paddingBottom: "13rem" }}
      >
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <div className="row align-items-center">
              <div className="offset-1 col-11">
                <div className="mx-auto p-2">
                  <p className="fs-4 fw-semibold text-start pb-0 discount-big pb-3">
                    Learn about Gifuto’s eGift
                  </p>
                  <ul className="lh-base fs-6 fw-normal text-start my-0">
                    <li>No Monthly Fees and No Contract</li>
                    <li>
                      Register in minutes and start selling your eGift cards
                      instantly
                    </li>
                    <li>Easy to use platform </li>
                    <li>
                      Customers can buy eGift cards on the go anywhere and
                      anytime
                    </li>
                    <li>Personalized eGift cards on your Business Name </li>
                    <li>eGift cards never expires</li>
                  </ul>
                </div>
              </div>
              <div className="offset-1 col-11 text-start py-md-2 py-5 px-4">
                <a href="/learn-more" className="ms-3">
                  View Details
                </a>
              </div>
            </div>
          </div>
          <div className="d-none1 d-md-block col-md-6">
            <div className="mx-auto p-2 ratio ratio-16x9">
              <video
                src="movie/WhatsApp Video 2024-02-18 at 10.28.04 AM.mp4"
                className="object-fit-contain"
                controls
                autoPlay
                muted
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
