import React from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CompanyBankEdit from "./components/business/CompanyBankEdit";
import Home from "./components/Home";
import UserLogin from "./components/user/UserLogin";
import Dashboard from "./components/business/dashboard/Dashboard";
import ForgotPassword from "./components/user/ForgotPassword";
import ResetPassword from "./components/user/ResetPassword";
import ForwardTo from "./components/user/ForwardTo";
import GifterDetails from "./components/gifter/GifterDetails";
import CCPayment from "./components/gifter/CCPayment";
import CCPaymentSuccess from "./components/gifter/CCPaymentSuccess";
import DisplayQRCode from "./components/business/DisplayQRCode";
import GiftCardDetails from "./components/giftcard/GiftCardDetails";
import UploadLogos from "./components/business/UploadLogos";
import GiftCardRedeem from "./components/giftcard/GiftCardRedeem";
import NotFound404 from "./components/common/NotFound404";
import RedeemGetCode from "./components/giftcard/GiftCardGetCode";
import CCPayemtConfirmation from "./components/gifter/CCPaymentConfirmation";
import CompanyRegComplete from "./components/user/CompanyRegComplete";
import LearnMore from "./components/LearnMore";
import About from "./components/About";
import HowItWorks from "./components/HowItWorks";
import Search from "./components/business/search/Search";
import Support from "./components/Support";
import CompanyUserRegistration from "./components/user/CompanyUserRegistration";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import Terms from "./components/common/Terms";
import CompanyUserAgreement from "./components/user/CompanyUserAgreement";
import AdminSearch from "./components/business/search/AdminSearch";
import DashboardAdmin from "./components/business/dashboard/DashboardAdmin";
import CompanyProfileEdit from "./components/business/CompanyProfileEdit";
import CompanyUsersEdit from "./components/business/CompanyUsersEdit";
import ActivateUserAccount from "./components/user/ActivateUserAccount";
import CompanySettingsEdit from "./components/business/CompanySettingsEdit";
import CompanyTranactions from "./components/business/CompanyTranactions";
import ReadMore from "./components/ReadMore";
import AuthWrapper from "./AuthWrapper";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/search" element={<Search />} />
          <Route path="/admin-search" element={<AdminSearch />} />
          <Route path="/support" element={<Support />} />
          <Route path="/learn-more" element={<LearnMore />} />
          <Route path="/read-more" element={<ReadMore />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/agreement" element={<CompanyUserAgreement />} />
          <Route path="/login" element={<UserLogin />} />
          <Route
            path="/company-user-reg"
            element={<CompanyUserRegistration />}
          />
          <Route
            path="/company-reg-complete"
            element={<CompanyRegComplete />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route
            path="/activate-account/:token"
            element={<ActivateUserAccount />}
          />
          <Route path="/forward" element={<ForwardTo />} />

          {/* Authenticated Routes  */}
          <Route element={<AuthWrapper />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/dashboard-admin/:business_id"
              element={<DashboardAdmin />}
            />
            <Route path="/company-bank-edit" element={<CompanyBankEdit />} />
            <Route
              path="/company-profile-edit"
              element={<CompanyProfileEdit />}
            />
            <Route
              path="/company-transactions"
              element={<CompanyTranactions />}
            />
            <Route path="/company-users-edit" element={<CompanyUsersEdit />} />
            <Route
              path="/company-settings-edit"
              element={<CompanySettingsEdit />}
            />
            <Route path="/qr-code" element={<DisplayQRCode />} />
            <Route path="/upload-logo" element={<UploadLogos />} />
            <Route path="/redeem" element={<RedeemGetCode />} />
            <Route
              path="/giftcard-redeem/:giftcard_id"
              element={<GiftCardRedeem />}
            />
          </Route>

          {/* Giftcard Buy Related */}
          <Route
            path="/gifter-details/:business_id"
            element={<GifterDetails />}
          />
          <Route
            path="/payment/:business_id/:gifter_id/:giftee_id/:giftcard_id/:amount/:email"
            element={<CCPayment />}
          />
          <Route
            path="/payment-success/:business_id/:gifter_id/:giftee_id/:giftcard_id"
            element={<CCPaymentSuccess />}
          />
          <Route
            path="/payment-confirmation/:giftcard_id"
            element={<CCPayemtConfirmation />}
          />
          <Route
            path="/giftcard-details/:giftcard_id"
            element={<GiftCardDetails />}
          />

          <Route path="*" exact={true} element={<NotFound404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
