import Header from "./common/Header";
import Footer from "./common/Footer";
export default function ReadMore() {
  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container col-xxl-12 col-lg-12 col-md-12 col-12 px-4 py-3 div-bank-info">
          <div className="row align-items-center g-4 py-3">
            <div className="text-center mb-1">
              <p className="fs-2 fw-bolder text-center pb-0 how-to-big">
                About Gifuto
              </p>
            </div>
          </div>
          <div className="row align-items-start">
            <div className="offset-md-1 col-md-5 col-12">
              <div className="mx-auto p-2">
                <p className="fs-6 fw-normal text-start pb-0">
                  Gifuto is a pioneering SaaS platform designed to facilitate
                  the sale of e-gift cards and streamline the redemption process
                  for businesses. Catering to a diverse clientele including
                  small businesses, service providers, and online shops, Gifuto
                  empowers these entities to effortlessly offer gift cards to
                  their customers, accessible for purchase both online and via
                  smartphones. By leveraging Gifuto's innovative solutions,
                  businesses can efficiently monitor and manage various aspects
                  of their gift card operations, including purchases,
                  redemptions, transactions, and balances, all through a
                  convenient online interface. This comprehensive platform not
                  only enhances the customer experience by simplifying the gift
                  card purchasing process but also provides businesses with
                  valuable tools to effectively track and optimize their gift
                  card programs.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-12">
              <img
                src="img/about-1.png"
                className="d-block mx-lg-auto img-fluid how-to-img1"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
          </div>
          <div className="row align-items-start my-5">
            <div className="offset-md-1 col-md-5 col-12">
              <img
                src="img/about-2.png"
                className="mx-lg-auto img-fluid d-none d-sm-block"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
            <div className="col-md-5 col-12">
              <div className="mx-auto p-2">
                <p className="fs-6 fw-normal text-start pb-0">
                  With Gifuto's SaaS platform, businesses gain a competitive
                  edge by harnessing the power of digital gift card solutions.
                  By integrating seamlessly into their existing operations,
                  Gifuto enables businesses to modernize their gift card
                  offerings and adapt to evolving consumer preferences. Whether
                  it's a local boutique, a freelance service provider, or an
                  independent contractor, Gifuto caters to businesses of all
                  sizes, empowering them to capitalize on the growing demand for
                  digital gift cards in today's market. Through its
                  user-friendly interface and comprehensive features, Gifuto
                  revolutionizes the way businesses engage with their customers
                  through gift card programs, fostering loyalty and driving
                  revenue growth in an increasingly digital landscape.
                </p>
              </div>
            </div>
            <div className="col-12">
              <img
                src="img/about-2.png"
                className="mx-lg-auto img-fluid d-block d-sm-none"
                alt="Bootstrap Themes"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
