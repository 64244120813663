import { useNavigate } from "react-router-dom";
import Header from "./common/Header";
import Footer from "./common/Footer";
export default function LearnMore() {
  const navigate = useNavigate();
  const onclick = () => {
    navigate("/company-user-reg");
  };
  return (
    <>
      <div className="container-fluid navbar-hero-div ">
        <Header />
        <div className="container-fluid col-xxl-12 col-lg-12 col-md-12 col-12 px-4 py-3 div-bank-info search-faq-div">
          <div className="container text-center px-5 py-5">
            <div className="row align-items-center g-4 py-3">
              <div className="text-center mb-1">
                <p className="fs-2 fw-bolder text-center pb-0 how-to-big">
                  EGIFT CARDS
                </p>
              </div>
            </div>
            <div className="row align-items-start mb-5">
              <div className="offset-1 col-5">
                <p className="fs-6 fw-bold text-start pb-1 mt-1 text-uppercase">
                  Challenges
                </p>
                {/* <p className="fs-6 fw-normal text-start pb-0"> */}
                <ul className="fs-6 fw-normal text-start pb-0">
                  <li>
                    Small, locally owned businesses, independent service
                    providers, and freelancers face obstacles in offering their
                    own e-Gift cards.
                  </li>
                  <li>
                    Lack of infrastructure and resources necessary for
                    establishing and managing e-Gift card programs effectively
                    is a primary challenge.
                  </li>
                  <li>
                    The upkeep of technology required for these programs poses a
                    burden, especially without dedicated IT departments.
                  </li>
                  <li>
                    Staying abreast of industry trends in the dynamic global
                    gift card market is challenging for smaller entities,
                    potentially impacting their competitiveness.
                  </li>
                  <li>
                    The risk of counterfeit gift vouchers poses financial risks
                    and threatens the reputation of these businesses.
                  </li>
                  <li>
                    Logistical issues such as record-keeping and redemption
                    tracking further compound the challenges, particularly
                    without proper tracking systems in place.
                  </li>
                </ul>
                {/* </p> */}
              </div>
              <div className="col-6">
                <img
                  src="img/Business Challenges.png"
                  className="d-block mx-lg-auto img-fluid how-to-img1"
                  alt="Bootstrap Themes"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="row align-items-start my-5">
              <div className="col-6">
                <img
                  src="img/MarketTrend.png"
                  className="d-block mx-lg-auto img-fluid how-to-img1"
                  alt="Bootstrap Themes"
                  loading="lazy"
                />
              </div>
              <div className="offset-1 col-5">
                <p className="fs-6 fw-bold text-start pb-1 mt-1 text-uppercase">
                  Market Trend
                </p>
                {/* <p className="fs-6 fw-normal text-start pb-0"> */}
                <ul className="fs-6 fw-normal text-start pb-0">
                  <li>
                    The demand for electronic gift cards is increasing as the
                    world embraces digital solutions.
                  </li>
                  <li>
                    According to Gift Card Gauge data, over 56% of consumers
                    plan to purchase gift cards from brick-and-mortar stores.
                  </li>
                  <li>
                    The impact of Covid-19 has accelerated the adoption of
                    digital solutions, with digital gift cards surpassing
                    physical counterparts in popularity.
                  </li>
                  <li>
                    The e-commerce boom, fueled by the rapid growth of online
                    shopping and mobile technology, has led to a preference for
                    e-Gift cards over physical ones.
                  </li>
                  <li>
                    The digital card market is expected to reach $1.5 trillion
                    by 2032.
                  </li>
                </ul>
                {/* </p> */}
              </div>
            </div>
            <div className="row align-items-start my-5">
              <div className="offset-1 col-5">
                <p className="fs-6 fw-bold text-start pb-1 mt-1 text-uppercase">
                  Benefits Of Offering e-Gift Cards
                </p>
                {/* <p className="fs-6 fw-normal text-start pb-0"> */}
                <ul className="fs-6 fw-normal text-start pb-0">
                  <li>
                    Increased spending: Redeeming gift cards tends to result in
                    increased spending, with recipients spending more than the
                    card value about 75% of the time.
                  </li>
                  <li>
                    Attracting new customers: Gift cards attract new customers,
                    with 34% of shoppers visiting new stores prompted by gift
                    cards, thereby enhancing customer adoption.
                  </li>
                  <li>
                    Promoting customer retention: Strategically, gift cards
                    promote customer retention by encouraging multiple visits,
                    positively impacting foot traffic metrics.
                  </li>
                  <li>
                    Facilitating new customer acquisition: Gift cards facilitate
                    new customer acquisition by introducing businesses to
                    potential long-term customers.
                  </li>
                  <li>
                    Providing flexibility to recipients: Offering e-Gift cards
                    provides flexibility to recipients in choosing what they
                    want, thereby reducing the likelihood of gift returns.
                  </li>
                  <li>
                    Acting as mini advertisements: Moreover, gift cards act as
                    mini advertisements, displaying a business's logo and
                    increasing brand awareness.
                  </li>
                  <li>
                    Easy inventory management: Operationally, easy inventory
                    management for eGift cards minimizes risks associated with
                    overstocking or understocking.
                  </li>
                  <li>
                    Driving seasonal sales: Seasonally, gift cards drive sales
                    during holidays and special occasions, providing a
                    significant boost.
                  </li>
                  <li>
                    Cross-selling opportunities: They also present opportunities
                    for cross-selling complementary products or services,
                    further enhancing sales potential.
                  </li>
                </ul>
                {/* </p> */}
              </div>
              <div className="col-6">
                <img
                  src="img/Benefits.png"
                  className="d-block mx-lg-auto img-fluid how-to-img1"
                  alt="Bootstrap Themes"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="row align-items-start my-5">
              <div className="col-6">
                <img
                  src="img/GifutoCanhelp.png"
                  className="d-block mx-lg-auto img-fluid how-to-img1"
                  alt="Bootstrap Themes"
                  loading="lazy"
                />
              </div>
              <div className="offset-1 col-5">
                <p className="fs-6 fw-bold text-start pb-1 mt-1 text-uppercase">
                  Gifuto Can Help With These Challenges
                </p>
                {/* <p className="fs-6 fw-normal text-start pb-0"> */}
                <ul className="fs-6 fw-normal text-start pb-0">
                  <li>
                    Hassle-free experience: GIFUTO offers a hassle-free
                    experience with no monthly fees and no contracts required.
                  </li>
                  <li>
                    Quick registration: Registration takes just minutes,
                    allowing you to start selling your eGift cards instantly.
                  </li>
                  <li>
                    User-friendly platform: Our user-friendly platform ensures
                    ease of use, empowering you to manage your eGift card
                    operations effortlessly.
                  </li>
                  <li>
                    Convenience for customers: Customers can purchase eGift
                    cards on the go, anytime and anywhere, providing them with
                    convenience and flexibility.
                  </li>
                  <li>
                    Brand personalization: Personalize your eGift cards with
                    your business name, enhancing brand recognition and customer
                    engagement.
                  </li>
                  <li>
                    No expiry dates: Rest assured that your eGift cards never
                    expire, offering peace of mind to both you and your
                    customers.
                  </li>
                  <li>
                    Benefits without commitment: With our platform, you can
                    unlock the benefits of digital gifting without any hassle or
                    commitment.
                  </li>
                </ul>
                {/* </p> */}
              </div>
            </div>
            <div className="row align-items-center py-5">
              <div className="col-12 text-center">
                <button
                  className="btn btn-light btn-sm navbar-button"
                  type="submit"
                  onClick={onclick}
                >
                  GET STARTED
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
