import Giftcards from "./dashboard/Giftcards";

export default function Redemptions({
  giftcardData,
  error,
  message,
  // redeemed,
  // remaining,
  // merchant,
}) {
  const include_transactions = "REDEEM";

  return (
    <>
      {/* <div className="row g-4">
        <div className="col-12 col-md-4">
          <div className="card mb-4 dashboard-bg-border">
            <div className="card-body">
              <h5 className="card-title">REDEMPTIONS</h5>
              <p className="card-text dashboard-orange">
                <FontAwesomeIcon
                  color="#EF9C39 !important"
                  icon={faTags}
                  className="dashboard-green"
                  style={{
                    width: "40px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                />
                <span className="ps-3">
                  {Number(redeemed).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                  })}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="card mb-4 dashboard-bg-border">
            <div className="card-body">
              <h5 className="card-title">CARD BALANCES</h5>
              <p className="card-text dashboard-orange">
                <FontAwesomeIcon
                  color="#EF9C39 !important"
                  icon={faCreditCardAlt}
                  className="dashboard-green"
                  style={{
                    width: "40px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                />
                <span className="ps-3">
                  {Number(remaining).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                  })}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="card mb-4 dashboard-bg-border">
            <div className="card-body">
              <h5 className="card-title">MERCHANT VALUE</h5>
              <p className="card-text dashboard-orange">
                <FontAwesomeIcon
                  color="#EF9C39 !important"
                  icon={faHandHoldingDollar}
                  className="dashboard-green"
                  style={{
                    width: "40px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                />
                <span className="ps-3">
                  {Number(merchant).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                  })}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- second row --> */}
      <div className="row g-4">
        <div className="col-12 col-md-12 dashboard-bg-border">
          <Giftcards
            data={giftcardData}
            error={error}
            message={message}
            include_transactions={include_transactions}
          />
        </div>
      </div>
    </>
  );
}
